import * as React from 'react';
import { Navigate, Outlet, Route, Router, useNavigate } from 'react-router';
import { BrowserRouter, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchRoutes, useLocation } from "react-router-dom"

import { Button, Backdrop, CircularProgress } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as App from 'AppReferences';
import ReactGA from "react-ga4";


//Pages
import Login from './Pages/User/Login/Login';
import ForgotPassword from './Pages/User/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from './Pages/User/ForgotPassword/ForgotPasswordSuccess';
import Register from './Pages/User/Register/Register';
import RegisterInviteRequest from './Pages/User/Register/RegisterInviteRequest';

import Test from './Pages/Test/Test';
import PasswordReset from './Pages/User/PasswordReset/PasswordReset';
import PasswordResetSuccess from './Pages/User/PasswordReset/PasswordResetSuccess';
import ConfirmEmail from './Pages/Account/ConfirmEmail/ConfirmEmail';
import ConfirmEmailToken from './Pages/Account/ConfirmEmail/ConfirmEmailToken';
import NotFound from './Pages/Static/NotFound';
import AccessDenied from './Pages/Static/AccessDenied';
import RedirectPage from './Pages/Static/RedirectPage';

import TermsOfService from './Pages/Static/Terms/TermsOfService';
import PrivacyPolicy from './Pages/Static/Terms/PrivacyPolicy';

import Account from './Pages/Account/Account';


import './App.css'

import { useParams } from "react-router-dom";

import { Component } from "react";
import Administration from 'Pages/Admin/Administration';
import EmailUnsubscribe from 'Pages/User/Email/EmailUnsubscribe';
import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import PaymentSuccess from 'Pages/Account/Billing/PaymentSuccess';
import  ListPage   from 'Pages/List/ListPage';
import { DetailPage } from 'Pages/Detail/DetailPage/DetailPage';
import FolderPage from 'Pages/Folder/FolderPage';
import Pricing from 'Pages/Pricing/Pricing';
import AccountSetup from 'Pages/AccountSetup/AccountSetup';




//class MainApp extends React.Component<App.GlobalState, {}> {
const MainApp: React.FC<App.GlobalState> = (props) => {

    var globalDataService: App.GlobalDataService = new App.GlobalDataService();
    var location = useLocation();
    var navigator = useNavigate();
    var params = useParams();

    App.AppBase.navigator = navigator;
    App.AppBase.location = location;
    App.AppBase.params = params;

    //var routerState: App.RouterState;
    /* 
        constructor(props: any) {
    
            super(props);
            this.globalDataService = new App.GlobalDataService();
    
    
        } */



    React.useEffect(() => {
        console.log("location change", location)
        console.log("location change params", params)

        var change = {
            location: location,
            navigator: navigator,
            params: params
        };

        App.AppStore.dispatch(App.ActionTypes.ROUTE_CHANGE, change);


    }, [ location ])

    React.useEffect(() => {
        console.log("MainApp useEffect update");

        fetchDataSequentially();
    }, []);//Run only Once



    const checkMobile = useIsMobile();
    React.useEffect(() => {

        if (App.AppBase.isMobile !== checkMobile.isMobile) {

            App.AppStore.dispatch(App.ActionTypes.GLOBAL_WINDOW_SIZE_CHANGED, checkMobile);
            console.log('isMobile: ', App.AppBase.isMobile);
        }

    }, [ checkMobile ]);


    async function fetchDataSequentially() {
        try {


            if (!App.AppBase.isAppInfoDataCalled) {
                console.log('fetchDataSequentially getAppInfoData call service');
                App.AppBase.isAppInfoDataCalled = true;
                const appInfoResponse = await globalDataService.getAppInfoData();
                console.log('fetchDataSequentially appInfoResponse: ', appInfoResponse);
            }
 
            if (App.AppBase.isUserAuthenticated) {
                console.log('fetchDataSequentially setInitialData call service');
                const initialDataResponse = await setInitialData(props);
                console.log('fetchDataSequentially initialDataResponse: ', initialDataResponse);
            }



        } catch (error) {
            // Handle the error
            console.error('fetchDataSequentially Error:', error);
        }
    }



    async function setInitialData(props: App.GlobalState) {
        //console.log('setInitialData - isInitialDataCalled',App.AppBase.isInitialDataCalled);
        //console.log('setInitialData - location.pathname',location.pathname);

        var state = props;

        var skip = false;
        if (location.pathname.includes('PaymentSuccess')) {
            skip = true;
        }

        // console.log('setInitialData - skip',skip);
        var locationParts = location.pathname.split('/');
        var w = locationParts[ 1 ] || -1;

        if (!App.AppBase.isInitialDataCalled && App.AppBase.isUserAuthenticated && !skip) {

            console.log('setInitialData call service, locationParts', locationParts);
            console.log('setInitialData call service, workspace', w);

            App.AppBase.isInitialDataCalled = true;
            await globalDataService.getInitialData(Number(w), (newState: any) => {
                console.log('setInitialData success...');
                //forward to workspace item 
                //  var newSelectedViewId = newState.workspaceVm?.selectedWorkspace?.selectedViewId;
                state.workspaceVm.selectedWorkspace = newState.workspaceVm?.selectedWorkspace;

                // console.log('setInitialData newSelectedViewId:',newSelectedViewId);
                console.log('setInitialData selectedWorkspace:', state?.workspaceVm?.selectedWorkspace);
                // console.log('setInitialData params.id:',params.id);

                /*  if(newSelectedViewId!==oldSelectedViewId && params.id==undefined){
                
                 } */

            });

        }

    }




    function getShowProgressPage(props: App.GlobalState) {

        var state = props;

        console.log('render getShowProgressPage');

        /*  console.log('render getShowProgressPage initialData.apiErrorMessage',state.initialData.apiErrorMessage);
         console.log('render getShowProgressPage props.apiErrorMessage',props.apiErrorMessage);
         console.log('render getShowProgressPage props',props);
         console.log('render getShowProgressPage props.globalUIState.apiErrorMessage',props.globalUIState.apiErrorMessage);
  */

        return <App.MainLayout

            errorMessage={state.initialData.apiErrorMessage || props.apiErrorMessage || props.globalUIState.apiErrorMessage}
            className='MainPageContent'
        >

            <LIB.Loading show={true} isModal={true} />


        </App.MainLayout>;

    }

    const  publicRoutes: any = [
        <Route key='Login' path='/Login' element={<Login />} />,
        <Route key='Register' path='/Register' element={<Register />} />,
        <Route key='Pricing' path='/Pricing' element={<Pricing />} />,

        <Route key='RegisterInviteRequest' path='/RegisterInviteRequest' element={<RegisterInviteRequest />} />,
        <Route key='Test' path='/Test' element={<Test />} />,


        <Route key='AccessDenied' path="/AccessDenied" element={<AccessDenied />} />,
        <Route key='NotFound' path="/NotFound" element={<NotFound />} />,
        <Route key='TermsOfService' path="/TermsOfService" element={<TermsOfService />} />,
        <Route key='PrivacyPolicy' path="/PrivacyPolicy" element={<PrivacyPolicy />} />,

        
        <Route key='PasswordReset' path='/User/PasswordReset' element={<PasswordReset />} />,
        <Route key='PasswordResetSuccess' path='/User/PasswordResetSuccess' element={<PasswordResetSuccess />} />,
        <Route key='ForgotPassword' path='/User/ForgotPassword' element={<ForgotPassword />} />,
        <Route key='ForgotPasswordSuccess' path='/User/ForgotPasswordSuccess' element={<ForgotPasswordSuccess />} />,
        <Route key='ConfirmEmailToken' path='/User/ConfirmEmailToken' element={<ConfirmEmailToken />} />,
        <Route key='ConfirmEmail' path='/Account/ConfirmEmail' element={<ConfirmEmail />} />,
        <Route key='EmailUnsubscribe' path='/EmailUnsubscribe' element={<EmailUnsubscribe />} />,

        <Route key='PaymentSuccess' path='/Account/PaymentSuccess' element={<PaymentSuccess />} />,
 
    ];



    function getApp(props: App.GlobalState) {

        console.log('App getApp');

        var state = props;

        App.AppBase.resetErrorMessages();
        props.globalUIState.apiErrorMessage = "";

        var selectedWorkspace = state.workspaceVm?.selectedWorkspace;
        var workspaceId = selectedWorkspace?.workspaceId || -1;

        //   console.log('getApp isUserAuthenticated', App.AppBase.isUserAuthenticated);
        //    console.log(`getApp currentPage |${currentPage}|`);
        //   console.log('getApp location', App.AppBase.history.location);

      //  var scrollingPages: string[] = [ 'account', 'test', 'pricing'];
        var pagesWith100Vh: string[] = [ 'list','account','folder','item'  ];




        var noLeftMenuPages: string[] = [ 'Account', 'Test', 'Candidates', 'Jobs', 'MyAccount' ];

        if (App.AppBase.isUserAuthenticatedAndEmailConfirmed) {
            noLeftMenuPages.push(''); //push empty/default page only if valid user. 
        }

       

        //*****************************PUBLIC ROUTES ******************************
        if (!App.AppBase.isUserAuthenticated) {

            return <App.MainLayout  
            pagesWith100Vh={pagesWith100Vh}
            >
                <Routes   >

                    <Route key='Login2' path='/' element={<Login />} />
                    {publicRoutes}
                    <Route key='NotFound2' path="*" element={<NotFound />} />

                </Routes>
            </App.MainLayout>;
        }

        console.log('App show private routes');





        //*****************************PRIVATE ROUTES ******************************
        return <App.MainLayout 
        pagesWith100Vh={pagesWith100Vh}
         >

            <Routes >
                {publicRoutes}


                {/*  ***************** Private Routes  ***************** */}
                <Route element={routeAuthorize()} >

                    <Route path='/' element={<ListPage />} />

                    {/*    <Route path='/Editor' element={<Editor />} />
                    <Route path='/GrapesJs' element={<GrapesJs />} />
 */}


                    <Route path='/Account' element={<Account />} />
                    <Route path='/Account/:tab' element={<Account />} />


                    <Route key='Administration' path='/Administration' element={<Administration />} />
                    <Route key='Administration2' path='/Administration/:tab' element={<Administration />} />
                    <Route key='Administration3' path='/Administration/:tab/:subtab' element={<Administration />} />

                    {/*        
                    <Route key='Sites' path='/Sites' element={<SitesList />} />
                    <Route key='Sites2' path='/Sites/:id' element={<PagesDetail />} />

                    <Route key='AdminCMS_Pages' path='/Pages' element={<PagesList />} />
                    <Route key='AdminCMS_Pages2' path='/Pages/:id' element={<PagesDetail />} /> */}

                    <Route key='ListPage1' path=':w/list' element={<ListPage />} />
                    <Route key='ListPage2' path=':w/list/:id' element={<ListPage />} />
                    <Route key='ListPage3' path=':w/list/:id/:linked' element={<ListPage />} />

                    <Route key='DetailPage1' path=':w/item/:listId/:rowId' element={<DetailPage />} />
                    <Route key='DetailPage2' path=':w/item/:listId/:rowId/:name' element={<DetailPage />} />
                    <Route key='DetailPage3' path=':w/item/:listId/:rowId/:name/:linked' element={<DetailPage />} />

                    <Route key='ListPage1' path=':w/folder' element={<FolderPage />} />
                    <Route key='ListPage2' path=':w/folder/:id' element={<FolderPage />} />
                    <Route key='ListPage3' path=':w/folder/:id/:linked' element={<FolderPage />} />

                    <Route key='AccountSetup1' path='AccountSetup' element={<AccountSetup />} />
                    <Route key='AccountSetup2' path='AccountSetup/:page' element={<AccountSetup />} />
                  
                </Route>


                {/*   <Route path="*" element={<RedirectPage />} />
              */}
                <Route key='NotFound3' path="*" element={<NotFound />} />

            </Routes>
        </App.MainLayout>;

    }


        
    var currentPage = App.RouteManager.getCurrentUrlPage().toLowerCase();

    var publicRouteIndex = publicRoutes.findIndex((x: any) => {
        let path = x.props.path.toLowerCase();
        //   console.log('publicRoute path: ',path);
        //  console.log('publicRoute currentPage: ',currentPage);
        var result = currentPage.startsWith(path);
        //  console.log('publicRoute result: ',result);
        return result;
    });

    var isPublicRoute = (publicRouteIndex > -1)

    console.log('isPublicRoute', isPublicRoute);
   // console.log('isPublicRoute currentPage', currentPage);
    // console.log('publicRoute path', path);
 
 
    const routeAuthorize = (role: App.UserRoleTypeEnum | null = null) => {

        console.log('routeAuthorize role:', role);


        // var page = App.RouteManager.getCurrentUrlPage().toLocaleLowerCase();
        var page = location.pathname.toLocaleLowerCase();
        var currentUser = App.AppBase.currentUser;
        var result: any = <Outlet />;
        var locationState: any = location.state;
        var isRedirect = locationState?.redirect;

        console.log('routeAuthorize page:', page);

        //  console.log('test22 routeAuthorize isUserAuthenticated:', App.AppBase.isUserAuthenticated);
        //  console.log('test22 routeAuthorize currentUser:', currentUser);


        //is Public route
        if (page.startsWith("/user") || page.startsWith("/login") || page.startsWith("/register") || page.startsWith("/email"))
            result = <Outlet />;
        else if (!App.AppBase.isUserAuthenticated) {
            result = <Navigate to="/Login" state={{ redirect: true }} />;
        }
        else if (!currentUser?.isEmailConfirmed && !isRedirect) {
            result = <Navigate to="/Account/ConfirmEmail" state={{ redirect: true }} />;
        }
        else if (!currentUser?.company?.hasActiveBillingPlan && !isRedirect) {
            result = <Navigate to="/AccountSetup" state={{ redirect: true }} />;
        }
        else if (!currentUser?.isProfileDetailsComplete && !isRedirect) {
            result = <Navigate to="/Account/Profile" state={{ redirect: true }} />;
        }
        else if (!currentUser?.company?.isCompanyDetailsComplete && !isRedirect && page !== '/account/profile') {
            result = <Navigate to="/Account/Company" state={{ redirect: true }} />;
        }

        /*  console.log('routeAuthorize company', currentUser?.company);
         console.log('routeAuthorize isCompanyDetailsComplete', currentUser?.company?.isCompanyDetailsComplete);
         console.log('routeAuthorize locationState', locationState);
         console.log('routeAuthorize location', location);
         console.log('routeAuthorize params', params);
         console.log('routeAuthorize isRedirect', isRedirect);
         console.log('routeAuthorize page', page);
         console.log('routeAuthorize result', result);
  */

        // console.log('auth: routeAuthorize result:', result);

        return result;

    }

    var state = props;
    var authTokenkey = App.AppBase.authTokenKey;
    var appState = App.AppBase.getState();

    var currentUser = App.AppBase.currentUser;
    //console.log('App render appWebsiteUrl:', App.AppBase.appInfo.appWebsiteUrl);

    ReactGA.initialize('G-1HR8B81CBM');

    console.log('App Render');


    if (!state.isAppInfoDataLoaded) {

        return getShowProgressPage(state);
    }
    else if(!state.isInitialDataLoaded && App.AppBase.isUserAuthenticated && !isPublicRoute){
 
        return getShowProgressPage(state);
    }  


    /* //Only show the app after inital data is loaded 
    else if(!state.isInitialDataLoaded && App.AppBase.isUserAuthenticated){

       
        return getShowProgressPage(state);
    } */

    return getApp(state);
}
export default connect(
    (state: App.AppState) => ({ ...state.globalData })
)(MainApp as any);

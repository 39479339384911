import "./LY_StatusDropDown_Edit_Options.css";

import * as Api from 'app-api';
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Button, ColorInput, ColorPicker, Input, Popover, Switch, Tooltip } from '@mantine/core';
import { DndContext, DragEndEvent, } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

import React from 'react';
import { getRandomColor } from "../../Constants/constants";

interface SortableItemProps {
    id: string;
    children: React.ReactNode;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className="LY_StatusDropdown_Option_sortable_item"
        >
            <div
                {...listeners}
                style={{ cursor: 'grab', display: 'inline-block' }}
                className="LY_StatusDropdown_Option_sortable_item-handle"
            >
                <Icons.IconGripVertical />
            </div>
            {children}
        </div>
    );
};

interface LY_StatusDropDown_Edit_Options_Props extends LY.LY_StatusDropDownProps {
    onInputChange: (key: string, value: any, item: Api.SystemLookupType, index: number) => void;
}

export const LY_StatusDropDown_Edit_Options: React.FC<LY_StatusDropDown_Edit_Options_Props> = (props) => {
    const context = LY.useStatusDropDownContext();
    const state = context.state;

    const [selectedColor, setSelectedColor] = React.useState("");
    const [deletedStatus, setDeletedStatus] = React.useState<Api.SystemLookupType | null>(null);
    const [search, setSearch] = React.useState('');
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [selectedColorItem, setSelectedColorItem] = React.useState<{ type?: string, color?: string, id?: string }>({});
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    function handleDeleteOption() {
        if (!deletedStatus?.id) return;
        context.deleteById(deletedStatus.id);
        setDeletedStatus(null);
    }

    function onOkNewColor() {
        const selected = state.statuses.find(i => i.id === selectedColorItem.id);
        if (selected) {
            if (selectedColorItem.type === 'text_color') {
                selected.text_color = selectedColor;
            }
            if (selectedColorItem.type === 'color') {
                selected.color = selectedColor;
            }
            context.itemChanged(selected);
        }
        setSelectedColorItem({});
    }

    function addOptionAndScroll() {
        const newStatus = new Api.SystemLookupType();
        newStatus.name = '';
        newStatus.text_color = '#ffffff';
        newStatus.id = LIB.StringHelper.generateUUID();
        newStatus.color = getRandomColor();
        newStatus.sort_order = state.statuses.length;
        context.addStatus(newStatus);
        scrollDown();
    }

    function scrollDown() {
        setTimeout(() => {
            if (wrapperRef.current) {
                wrapperRef.current.scrollTo({
                    top: wrapperRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, 100);
    }

    function searchInputRenderer() {
        if (state.statuses.length < 5)
            return null;

        return <Input
            classNames={{
                input: "LY_StatusDropdown_Options_input",
                wrapper: "LY_StatusDropdown_Options_input_wrapper"
            }}
            value={search}
            placeholder="Search Status..."
            onChange={e => setSearch(e.target.value)}
            leftSection={<Icons.IconSearch width={22} height={22} />}
        />;
    }

    function getColorPickerCircle(tooltip: string, item: Api.SystemLookupType, type: string) {
        const colorObj = {
            type: 'text_color',
            color: item.text_color,
            backgroundColor: item.text_color,
            id: item.id
        };

        if (type === 'color') {
            colorObj.type = 'color';
            colorObj.backgroundColor = item.color;
        }

        if (!colorObj.backgroundColor)
            colorObj.backgroundColor = "#EFEFEF";

        const border = colorObj.backgroundColor?.startsWith("w") ||
            colorObj.backgroundColor?.startsWith("#f") ||
            colorObj.backgroundColor?.startsWith("rgb(255") ? "1px solid #e2e2e2" : "";

        return (

            <Popover
                withArrow
                position="bottom"
                opened={
                    selectedColorItem.id === item.id
                    && selectedColorItem.type === type
                }
            >
                <Popover.Target>
                    <Tooltip label={tooltip}>
                        <div
                            className="LY_StatusDropdown_Options_item_color_picker"
                            onClick={() => {
                                const color = (type === "color" ? colorObj?.backgroundColor : colorObj.color) || ""
                                setSelectedColor(color)
                                setSelectedColorItem(colorObj);
                            }}
                            style={{ backgroundColor: colorObj.backgroundColor || "#0C74E1", border: border }}
                        ></div>
                    </Tooltip>
                </Popover.Target>
                <Popover.Dropdown
                    classNames={{
                        dropdown: 'LY_PopoverRoundCorners',
                        arrow: 'LY_PopoverRoundCorners-arrow',
                    }} styles={{
                        dropdown: {
                            padding: '10px'
                        }
                    }}>
                    {pickerRenderer()}
                </Popover.Dropdown>
            </Popover>
        );
    }


    function getRenderOptionItem(index: number, item: Api.SystemLookupType) {
        const nameInputName = 'edit_option_name' + index;
        const valueInputName = 'edit_option_value' + index;

        const type = props.valueProperty === 'value_id' ? 'number' : 'text';

        return (
            <SortableItem key={item.sort_order!} id={item.id!}>
                <MT.TextInput
                    name={nameInputName}
                    value={item.name}
                    leftSection={
                        <div className="LY_StatusDropdown_Options_item_left_section">
                            {getColorPickerCircle("Text Color", item, 'text_color')}
                            {getColorPickerCircle("Background Color", item, 'color')}
                        </div>
                    }
                    onChange={e => {
                        props.onInputChange('name', e.target.value, item, index);
                    }}
                    classNames={{
                        wrapper: 'LY_StatusDropwdown_Options_item_input_wrapper',
                        input: 'LY_StatusDropdown_Options_item_input',
                        root: 'LY_StatusDropdown_Options_item_input_root',
                        section: 'LY_StatusDropdown_Options_item_left_section_wrapper'
                    }}
                    placeholder="Name"
                    error={state.errors.get(nameInputName)}
                />

                {state.isShowingAdvancedSettings && props.valueProperty !== 'id' && (
                    <Input
                        name={valueInputName}
                        type={type.toLowerCase()}
                        value={item[props.valueProperty as keyof Api.SystemLookupType] as string || ""}
                        onChange={e => {
                            props.onInputChange(props.valueProperty, e.target.value, item, index);
                        }}
                        classNames={{
                            wrapper: 'LY_StatusDropdown_Options_item_advancedInput_wrapper',
                            input: 'LY_StatusDropdown_Options_item_advancedInput'
                        }}
                        placeholder={`${type || ""} Value`}
                        error={state.errors.get(valueInputName)}
                    />
                )}

                <MT.CloseButton
                    className="LY_StatusDropdown_Options_item_delete"
                    icon={
                        <Icons.IconTrash
                            stroke={'1.5'}
                            style={{ minWidth: '16px', width: '16px' }}
                            aria-label="Delete Item"
                        />
                    }
                    onClick={() => setDeletedStatus(item)}
                />
            </SortableItem>
        );
    }

    function renderOptions() {
        return (
            <Popover
                opened={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                width="auto"
                position="bottom"
                withArrow
            >
                <div className="LY_StatusDropdown_Options_wrapper LY_Scrollbar" ref={wrapperRef}>
                    <LIB.Loading show={state.vm.isActionInProgress} isModal disablePortal />
                    <SortableContext
                        items={state.statuses.map(status => status.id!)}
                        strategy={verticalListSortingStrategy}
                    >
                        {state.statuses.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
                            .map((item, index) => getRenderOptionItem(index, item))
                        }
                    </SortableContext>
                </div>
            </Popover>
        );
    }

    function pickerRenderer() {
        return (
            <div
                style={{ zIndex: 9999, display: 'flex', flexDirection: 'column', rowGap: '2px' }}
            >
                <ColorInput
                    style={{ alignSelf: 'flex-end' }}
                    classNames={{
                        input: 'LY_StatusDropdown_Options_item_picker_input',
                        root: 'LY_StatusDropdown_Options_item_picker_input_wrapper',
                    }}
                    readOnly
                    value={selectedColor}
                />
                <ColorPicker
                    classNames={{
                        saturation: 'LY_StatusDropdown_Options_item_picker_saturation',
                    }}
                    format="hex"
                    value={selectedColor}
                    onChange={(color) => setSelectedColor(color)}
                    swatchesPerRow={10}
                    swatches={[
                        '#2e2e2e', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886',
                        '#40c057', '#82c91e', '#fab005', '#fd7e14', "#f3f3fe", "#e4e6ed", "#c8cad3", "#f28043", "#f06d27", "#a9adb9",
                        "#9093a4", "#808496", "#767c91", "#656a7e", "#585e72", "#4a5167", "#fff0e4", "#ffe0cf", "#fac0a1", "#f69e6e",
                    ]}
                />
                <div className='LY_StatusSelect_ButtonGroup'>
                    <LY.LY_ButtonCancel
                        onClick={() => {
                            setSelectedColorItem({});
                        }}
                    >
                        Cancel
                    </LY.LY_ButtonCancel>
                    <LY.LY_Button
                        onClick={onOkNewColor}
                    >
                        Ok
                    </LY.LY_Button>
                </div>
            </div>
        );
    }

    function advancedFilterRenderer() {
        return (
            <div className="LY_StatusDropdown_Options_add_option">
                <Button
                    size="xs"
                    onClick={addOptionAndScroll}
                    classNames={{
                        root: 'LY_StaticSelect_Options_add_option_button',
                        inner: 'LY_StaticSelect_Options_add_option_button_inner',
                    }}
                    styles={{
                        section:{marginRight:5}
                    }}
                    variant='outline'
                    leftSection={<Icons.IconPlus stroke={1} size={20} />}
                >
                  Add
                </Button>

                {!state.isShowAdvancedSettingsDisabled &&
                    <Switch
                        label="Advanced Settings"
                        labelPosition='left'
                        onClick={(e) => {
                            state.isShowingAdvancedSettings = e.currentTarget.checked;
                            context.forceUpdate();
                        }}
                        checked={state.isShowingAdvancedSettings}
                        disabled={state.isShowAdvancedSettingsDisabled}
                        classNames={{
                            trackLabel: 'LY_StatusDropdown_Options_switch_track_label',
                            body: 'LY_StatusDropdown_Options_switch_body'
                        }}
                    />
                }
            </div>
        );
    }

    function deleteConfirmationModalRenderer() {
        return (
            <LIB.ConfirmModal
                visible={Boolean(deletedStatus?.id)}
                onCancelClick={() => setDeletedStatus(null)}
                onConfirmClick={handleDeleteOption}
                title={<div>Confirm Delete: <b>{deletedStatus?.name}</b></div>}
                content={
                    <div>Are you sure you want to delete the <b>{deletedStatus?.name}</b> status?
                        <MT.Alert
                            variant="light"
                            color="red"
                            icon={<Icons.IconAlertTriangle />}
                            style={{ marginTop: 10, padding: 8 }}
                        >
                            There is NO Rollback for this action!
                        </MT.Alert>
                    </div>
                }
                confirmButtonLabel="Delete"
            />
        );
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const fromIndex = state.statuses.findIndex(status => status.id === active.id);
            const toIndex = state.statuses.findIndex(status => status.id === over?.id);
            context.moveStatus(fromIndex, toIndex); // Use the moveStatus function
        }
    }

    return (
        <DndContext onDragEnd={handleDragEnd}>
            {searchInputRenderer()}
            {renderOptions()}
            {advancedFilterRenderer()}

            {deleteConfirmationModalRenderer()}
        </DndContext>
    );
};

export default LY_StatusDropDown_Edit_Options;

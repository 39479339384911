import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { createContext, useContext, useState } from "react";

import { LY_StaticDropDownModuleManager } from '../Manager/LY_StaticDropDownModuleManager';
import { LY_StaticDropDownState } from './LY_StaticDropDownState';
import React from "react";

export interface LY_StaticDropDownContextProps {
    state: LY_StaticDropDownState;
    forceUpdate: () => void;
    saveChanges: () => void;
    saveColumnChanges: () => void;
    changeEditMode: (mode: boolean) => void;
    changeSelectedValue: (value?: Api.SystemLookupType) => void;
    setPopoverOpened: (opened: boolean) => void;
    deleteById: (id: string) => void;
    addItem: (item: Api.SystemLookupType) => void;
    itemChanged: (item: Api.SystemLookupType) => void;
    setInitialColumns: (column: Api.ListColumn) => void;
    moveItems: (fromIndex: number, toIndex: number) => void;
}

const useDropDownModuleState = (props: LY.LY_StaticDropDownProps): LY_StaticDropDownContextProps => {


    const initialState = new LY_StaticDropDownState();

    const [state, setState] = useState<LY_StaticDropDownState>(initialState);
    const [propsState, setPropsState] = useState<LY_StaticDropDownState>(new LY_StaticDropDownState());

    const manager = new LY_StaticDropDownModuleManager(props, state, forceUpdate);


    // console.log(state)

    React.useEffect(() => {

        
        if(state.isSelectStatusPopoverOpen)
            return;


       /*  console.log('LY_StaticDropDownContext useEffect');
        console.log('LY_StaticDropDownContext useEffect props.selectedValue:', props.selectedValue);
        console.log('LY_StaticDropDownContext useEffect props.selectedValues:', props.selectedValues);
        console.log('LY_StaticDropDownContext useEffect  props.data:',  props.data); */

        state.model = props.model;
        state.items = props.data || [];
        state.selectedItems = [];

        if(props.selectedValues && props.selectedValues.length > 0)
            state.selectedItems = props.selectedValues;
        else if(props.selectedValue){
            state.selectedItems = [props.selectedValue];
        }

/* 
        if(props.selectedValues && props.selectedValues.length > 0){
            state.selectedItems = (props.data || []).filter(x => {
                let itemObj = x[props.valueProperty as keyof Api.SystemLookupType];
                return (props.selectedValues || []).includes(itemObj as unknown as Api.SystemLookupType);
            });
        }else if(props.selectedValue){
           
            let itemObj = props.data?.find(option => option[props.valueProperty as keyof Api.SystemLookupType] === props.selectedValue);

            state.selectedItems =itemObj? [itemObj] : [];

        } */

       // state.selectedItems = props.allowMultiSelect ? props.selectedValues?.length ? props.selectedValues : props.selectedValue ? [props.selectedValue] : [] : [];
      
      
       // console.log('LY_StaticDropDownContext useEffect state.selectedItems:', state.selectedItems);

        state.isShowingAdvancedSettings = (props.valueProperty === 'value' || props.valueProperty === 'value_id');
        state.isShowAdvancedSettingsDisabled = !state.isShowingAdvancedSettings;
        state.allowMultipleSelection = props.allowMultiSelect;
        forceUpdate();

        var stateCopy = clone(state);
        setPropsState(stateCopy);

    }, [props.data, props.model]);



    function clone(stateIn: LY_StaticDropDownState): LY_StaticDropDownState {

        var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_StaticDropDownState;
        stateCopy.errors = new Map<string, any>();
        return stateCopy;
    }

    function forceUpdate() {
        console.log('LY_StaticDropDownContext forceUpdate');

        setState({ ...state })
    }

    function saveColumnChanges() {
        manager.onColumnChange();
    }

    function saveChanges() {
        state.items.forEach((item, index) => {
            item.sort_order = index + 1;
            itemChanged(item);
        })
        if (!state.chagnedItems || state.chagnedItems.length == 0) {
            state.isEditMode = false;
            forceUpdate();
            return;
        }


        manager.saveChanges(state.chagnedItems);


    }

    function itemChanged(item: Api.SystemLookupType) {


        //check if it's already in the list
        var found = state.chagnedItems.find(i => i.id === item.id);
        if (found) {
            forceUpdate();
            return;
        }

        // check if something changed
        var original = propsState.items.find(i => i.id === item.id);
        if (!original) {
            forceUpdate();
            return;
        }

        if (item.color !== original.color ||
            item.text_color !== original.text_color ||
            item.name !== original.name ||
            item.value !== original.value ||
            item.value_id !== original.value_id) {

            item.isChanged = true;
            state.chagnedItems.push(item);
            state.items = [...state.items];

            forceUpdate();
        }

    }



    function deleteById(id: string) {

        var found = state.items.find(i => i.id === id);
        if (!found) {
            console.log('LY_StaticDropDownContext deleteById not found:', id);
            return;
        }

        //let's remove it from the list
        var index = state.items.indexOf(found);
        state.items.splice(index, 1);
        state.items = [...state.items];

        if (found.isNew) {
            //let's check and remove from  state.chagnedItems
            var foundChanged = state.chagnedItems.find(i => i.id === id);
            if (foundChanged) {
                var index = state.chagnedItems.indexOf(foundChanged);
                state.chagnedItems.splice(index, 1);
            }
            return;
        }

        state.isSelectStatusPopoverOpen = false;
        found._ly_date_deleted = new Date();

        //keep here to save when Save
        state.chagnedItems.push(found);

        forceUpdate();


    }


    function setInitialColumns(column: Api.ListColumn) {

        if (!column || !column.listColumnId)
            return;

        state.model.column = column;

        forceUpdate();
    }



    function setPopoverOpened(opened: boolean) {
        setState({ ...state, isSelectStatusPopoverOpen: opened });
    }


    function changeSelectedValue(value?: Api.SystemLookupType) {

        console.log('LY_StaticDropDownContext changeSelectedValue value:', value );

        if(!value){
            state.selectedItems = [];
            return;
        }

        var selectedValId = value![props.valueProperty as keyof Api.SystemLookupType];

        console.log('LY_StaticDropDownContext changeSelectedValue selectedValId:', selectedValId);

        if(!state.selectedItems)
            state.selectedItems = [];

        if (props.allowMultiSelect) {

            //if already exists, delete
            
            if (state?.selectedItems?.filter(item => item[props.valueProperty as keyof Api.SystemLookupType] === selectedValId)?.length > 0) {
                state.selectedItems = state?.selectedItems?.filter(item => item[props.valueProperty as keyof Api.SystemLookupType]!== selectedValId);

            } 
            // if not, add it
            else if (value && state.selectedItems) {
                state.selectedItems.push(value);

            }  
            var valueIds = state.selectedItems?.map(x=>x[props.valueProperty as keyof Api.SystemLookupType]);

            console.log('LY_StaticDropDownContext changeSelectedValue multi: state.selectedItems ', state?.selectedItems );
            console.log('LY_StaticDropDownContext changeSelectedValue multi: valueIds ', valueIds );

            state.model.value = valueIds; 

           // state.isSelectStatusPopoverOpen = true;

        } else if (value) {

            console.log('LY_StaticDropDownContext changeSelectedValue single: value', value);
 
            state.model.value = selectedValId; 

            state.selectedItems = [value];
        }

        state.isSelectStatusPopoverOpen = false;

        forceUpdate();

    }


    function changeEditMode(mode: boolean) {
        state.isEditMode = mode;
        forceUpdate();

    }


    function addItem(status: Api.SystemLookupType) {

        status.list_id = state.model?.listId;
        status.column_id = state.model?.column?.listColumnId;
        status.workspace_id = props.workspaceId;
        status.isNew = true;
        status.sort_order = 1;

        if (state.items) {
            var lastItem = state.items[state.items.length - 1];
            status.sort_order = lastItem?.sort_order ? lastItem.sort_order + 1 : 1;
        }

        if (props.allowMultiSelect) {
            state.isSelectStatusPopoverOpen = true;
        }
        //keep here to save when Save is called
        state.chagnedItems.push(status);




        state.items.push(status);
        forceUpdate();



    }
    function moveItems(fromIndex: number, toIndex: number) {
        if (fromIndex === toIndex) return;
        const items = state.items;
        const [movedStatus] = items.splice(fromIndex, 1);
        items.splice(toIndex, 0, movedStatus);
        forceUpdate();
    };

    return {
        state,
        forceUpdate,
        saveChanges,
        saveColumnChanges,
        changeEditMode,
        changeSelectedValue,
        setPopoverOpened,
        deleteById,
        addItem,
        itemChanged,
        setInitialColumns,
        moveItems
    };
};

const LY_StaticDropDownContext = createContext<LY_StaticDropDownContextProps | undefined>(undefined);

export const useStaticDropDownContext = () => {


    const context = useContext(LY_StaticDropDownContext);

    if (!context) {
        throw new Error(
            "useLY_StaticDropDownContext must be used within a ListTableViewProvider"
        );
    }
    return context;
};


const LY_StaticDropDownContextProvider: React.FC<LY.LY_StaticDropDownProps & { children: React.ReactNode }> = (props) => {


    const contextValue = useDropDownModuleState(props);

    function render() {

        return (
            <LY_StaticDropDownContext.Provider value={contextValue}>
                {props.children}
            </LY_StaticDropDownContext.Provider>
        );
    }

    return render();
};
export { LY_StaticDropDownContextProvider, LY_StaticDropDownContext };

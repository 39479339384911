import './LY_StatusDropDown_Edit.css';

import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { LY_DropDown_Footer } from '../LY_StatusDropDown_Footer/LY_StatusDropDown_Footer';
import LY_StatusDropDown_Edit_Options from '../LY_StatusDropDown_Edit_Options/LY_StatusDropDown_Edit_Options';
import LY_StatusSelect_Styles from '../LY_StatusSelect_Styles/LY_StatusDropDown_Styles';

export const LY_StatusDropDown_Edit: React.FC<LY.LY_StatusDropDownProps> = (props) => {


    const context = LY.useStatusDropDownContext();
    const state = context.state;


    function onSaveClick() {


        var isValid = validateAll();
        if (!isValid) {
            console.log('LY_DropDown_Edit onSaveClick isValid:', isValid);

            context.forceUpdate();
            return false;
        }

        console.log('LY_DropDown_Edit onSaveClick isValid:', isValid);

        context.saveChanges();
        context.saveColumnChanges();




    }

    function onInputChange(key: string, value: any, item: Api.SystemLookupType, index: number) {

        console.log('LY_DropDown_Edit_Options onInputChange key:', key, index);


        //@ts-ignore
        item[key] = value;

        // console.log('LY_DropDown_Edit_Options onInputChange item updated:', item, index);

        validateOne(key, item, index);

        context.itemChanged(item);

        //context.forceUpdate();

    }

    function validateAll(): boolean {

        var items = state?.statuses;

        if (!items) return false;


        state.errors.clear();

        items.map((item, index) => {
            validateOne('all', item, index);


        });

        var isValid = (state.errors.size < 1);

        return isValid;

    }

    function validateOne(key: string, item: Api.SystemLookupType, index: number): boolean {

        //  console.log('LY_DropDown_Edit_Options validate key:', key);

        var nameInputName = 'edit_option_name' + index;
        var valueInputName = 'edit_option_value' + index;

        var vAll = (key === 'all'); //validate all

        if (!vAll)
            state.errors.clear();



        if ((vAll || key == 'name') && LIB.Common.isNullOrEmpty(item.name)) {
            state.errors.set(nameInputName, `Name is required!`);
        }

        if ((vAll || key == 'value') && state.isShowingAdvancedSettings) {

            if ((!props.valueProperty || props.valueProperty == 'value') && LIB.Common.isNullOrEmpty(item.value)) {
                state.errors.set(valueInputName, `Value is required!`);

            }
            else if ((!props.valueProperty || props.valueProperty == 'value_id') && item.value_id == undefined) {
                state.errors.set(valueInputName, `Value is required!`);

            }
        }

        var isValid = (state.errors.size < 1);

        return isValid;

    }




    function tabsRenderer() {
        return <MT.Tabs.List>
            <MT.Tabs.Tab
                classNames={{
                    tab: 'LY_StatusDropdown_Settings_tab_section',
                }}
                value="options"
            >
                Options
            </MT.Tabs.Tab>
            <MT.Tabs.Tab
                classNames={{
                    tab: 'LY_StatusDropdown_Settings_tab_section',
                }}
                value="style"
            >
                Style
            </MT.Tabs.Tab>
        </MT.Tabs.List>
    }


    function panelRenderer() {
        return <>
            <MT.Tabs.Panel value="options" classNames={{ panel: 'LY_StatusDropdown_Settings_Options_panel' }}>
                <LY_StatusDropDown_Edit_Options {...props} onInputChange={onInputChange} />
            </MT.Tabs.Panel>
            <MT.Tabs.Panel value="style">
                <MT.Stack>
                    <LY_StatusSelect_Styles />
                </MT.Stack>
            </MT.Tabs.Panel>
            <LY_DropDown_Footer {...props} onSaveClick={onSaveClick} />
        </>
    }


    function render() {
        return <MT.Tabs defaultValue="options" styles={{ root: { width: '400px' } }}>
            {tabsRenderer()}
            {panelRenderer()}
        </MT.Tabs>
    }


    return render();
}
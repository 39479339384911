import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { createContext, useContext, useState } from "react";

import { LY_DropDownModuleManager } from "../Manager/LY_DropDownModuleManager";
import { LY_StatusDropDownState } from "./LY_StatusDropDownState";
import React from "react";

export interface LY_StatusDropDownContextProps {
    state: LY_StatusDropDownState;
    forceUpdate: () => void;
    saveChanges: () => void;
    saveColumnChanges: () => void;


    changeEditMode: (mode: boolean) => void;
    changeSelectedValue: (value?: Api.SystemLookupType) => void;
    setPopoverOpened: (opened: boolean) => void;
    cancelClick: () => void;
    moveStatus: (fromIndex: number, toIndex: number) => void;
    deleteById: (id: string) => void;
    addStatus: (status: Api.SystemLookupType) => void;
    itemChanged: (item: Api.SystemLookupType) => void;
    setInitialColumns: (column: Api.ListColumn) => void;
    changeNewStatuses: (newStatuses: Api.SystemLookupType[]) => void;
}

const useDropDownModuleState = (props: LY.LY_StatusDropDownProps): LY_StatusDropDownContextProps => {


    const initialState = new LY_StatusDropDownState();

    const [state, setState] = useState<LY_StatusDropDownState>(initialState);
    const [propsState, setPropsState] = useState<LY_StatusDropDownState>(new LY_StatusDropDownState());

    const manager = new LY_DropDownModuleManager(props, state, forceUpdate);


    React.useEffect(() => {


        if(state.isSelectStatusPopoverOpen)
            return;

        const value = props.data?.find(option => option[props.valueProperty as keyof Api.SystemLookupType] === props.value);
        state.defaultStatuses = LIB.ObjectHelper.clone(props.data);
        state.model = props.model;
        state.statuses = props.data || [];
        state.selectedItem = value;
        state.isShowingAdvancedSettings = (props.valueProperty === 'value' || props.valueProperty === 'value_id');
        state.isShowAdvancedSettingsDisabled = !state.isShowingAdvancedSettings;
        forceUpdate();


        var stateCopy = clone(state);
        setPropsState(stateCopy);

    }, [props.data, props.model]);


    const moveStatus = (fromIndex: number, toIndex: number) => {
        if (fromIndex === toIndex) return;
        const statuses = state.statuses;
        const [movedStatus] = statuses.splice(fromIndex, 1);
        statuses.splice(toIndex, 0, movedStatus);
        forceUpdate();
    };


    function clone(stateIn: LY_StatusDropDownState): LY_StatusDropDownState {

        var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_StatusDropDownState;
        stateCopy.errors = new Map<string, any>();
        return stateCopy;
    }

    function forceUpdate() {

        setState({ ...state })
    }

    function saveColumnChanges() {
        if (state.model.column?.textColor !== propsState.model.column.textColor ||
            state.model.column.valueBackgroundColor !== propsState.model.column.valueBackgroundColor ||
            state.model.column.styleRadius !== propsState.model.column.styleRadius ||
            state.model.column.styleVariant !== propsState.model.column.styleVariant

        ) {

            manager.onColumnChange();
        }
    }

    function saveChanges() {
        state.statuses.forEach((status, index) => {
            status.sort_order = index + 1;
            itemChanged(status);
        })
        if (!state.chagnedItems || state.chagnedItems.length == 0) {
            state.isEditMode = false;
            forceUpdate();
            return;
        }
        console.log('LY_StatusDropDownContext saveChanges state.chagnedItems:', state.chagnedItems);

        manager.saveChanges(state.chagnedItems);

    }



    function cancelClick() {

        console.log('setInitialView orderByColumns 1:', '======================');

        var state = clone(propsState);
        setState(state);

    }
    function itemChanged(item: Api.SystemLookupType) {


        //check if it's already in the list
        var found = state.chagnedItems.find(i => i.id === item.id);
        if (found) {
            forceUpdate();
            return;
        }

        // check if something changed
        var original = propsState.statuses.find(i => i.id === item.id);
        if (!original) {
            forceUpdate();
            return;
        }

        if (item.color !== original.color ||
            item.text_color !== original.text_color ||
            item.name !== original.name ||
            item.value !== original.value ||
            item.value_id !== original.value_id ||
            item.sort_order !== original.sort_order
        ) {

            item.isChanged = true;
            state.chagnedItems.push(item);
            // state.statuses = [...state.statuses];

        }
        else {
            console.log('LY_StatusDropDownContext itemChanged no changes');

        }

        forceUpdate();
    }



    function deleteById(id: string) {

        var found = state.statuses.find(i => i.id === id);
        if (!found) {
            return;
        }

        //let's remove it from the list
        var index = state.statuses.indexOf(found);
        state.statuses.splice(index, 1);
        state.statuses = [...state.statuses];

        if (found.isNew) {
            //let's check and remove from  state.chagnedItems
            var foundChanged = state.chagnedItems.find(i => i.id === id);
            if (foundChanged) {
                var index = state.chagnedItems.indexOf(foundChanged);
                state.chagnedItems.splice(index, 1);
            }
            return;
        }


        found._ly_date_deleted = new Date();

        //keep here to save when Save
        state.chagnedItems.push(found);

        forceUpdate();


    }


    function setInitialColumns(column: Api.ListColumn) {

        if (!column || !column.listColumnId)
            return;

        state.model.column = column;

        forceUpdate();
    }



    function setPopoverOpened(opened: boolean) {
        setState({ ...state, isSelectStatusPopoverOpen: opened });
    }


    function changeSelectedValue(value?: Api.SystemLookupType) {

        var val = value?value[props.valueProperty as keyof Api.SystemLookupType]:undefined;

        state.model.value = val; // or value or value_id depending on the dat
        state.isSelectStatusPopoverOpen = false;
        state.selectedItem = value;
        forceUpdate();

    }


    function changeEditMode(mode: boolean) {
        state.isEditMode = mode;
        forceUpdate();

    }


    function addStatus(status: Api.SystemLookupType) {

        status.list_id = state.model?.listId;
        status.column_id = state.model?.column?.listColumnId;
        status.workspace_id = props.workspaceId;
        status.isNew = true;
        status.sort_order = 1;

        if (state.statuses.length) {
            var lastItem = state.statuses[state.statuses.length - 1];
            status.sort_order = lastItem?.sort_order ? lastItem.sort_order + 1 : 1;
        }


        //keep here to save when Save is called
        state.chagnedItems.push(status);


        state.statuses.push(status);
        forceUpdate();



    }


    function changeNewStatuses(newStatuses: Api.SystemLookupType[]) {
        setState({ ...state, newStatuses });

    }

    return {
        state,
        forceUpdate,
        saveChanges,
        saveColumnChanges,
        changeEditMode,
        changeSelectedValue,
        setPopoverOpened,
        deleteById,
        addStatus,
        itemChanged,
        setInitialColumns,
        changeNewStatuses,
        moveStatus,
        cancelClick
    };
};

const LY_StatusDropDownContext = createContext<LY_StatusDropDownContextProps | undefined>(undefined);

export const useStatusDropDownContext = () => {


    const context = useContext(LY_StatusDropDownContext);

    if (!context) {
        throw new Error(
            "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
        );
    }
    return context;
};


const LY_StatusDropDownContextProvider: React.FC<LY.LY_StatusDropDownProps & { children: React.ReactNode }> = (props) => {


    const contextValue = useDropDownModuleState(props);

    function render() {

        //console.log('LY_StatusDropDownContextProvider render:', contextValue.state);
        // console.log('LY_StatusDropDownContextProvider render view:', contextValue.state?.view);
        // console.log('LY_StatusDropDownContextProvider render view.filter:', contextValue.state?.view?.filter);

        return (
            <LY_StatusDropDownContext.Provider value={contextValue}>
                {props.children}
            </LY_StatusDropDownContext.Provider>
        );
    }

    return render();
};
export { LY_StatusDropDownContextProvider, LY_StatusDropDownContext };

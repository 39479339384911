import "./LY_StaticDropDown_Options.css"

import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { buttonStyles, sizes } from "../../Constants/constants";

import { Badge } from "@mantine/core";
import { LY_StaticDropDown_Footer } from "../LY_StaticDropDown_Footer/LY_StaticDropDown_Footer";
import React from "react";

export const LY_StaticDropDown_Options: React.FC<LY.LY_StaticDropDownProps> = (props) => {



    const context = LY.useStaticDropDownContext();
    const state = context.state;
    const isWide = state.items?.length > 5;


    const [isHoveredId, setIsHovered] = React.useState<string>('');

    const handleMouseEnter = (id: string) => {
        setIsHovered(id);
    };

    const handleMouseLeave = (id: string) => {
        setIsHovered(id);
    };
    return <>
        <div
            className='LY_Static_List_Renderer LY_Scrollbar'
        >
            <LIB.Loading show={state.isActionInProgress} isModal />
            {state.items?.map((item, index) => {
                var text_color = item?.text_color || '#000';
                if (state?.selectedItems?.filter(i => i?.id === item.id).length) {
                    return <Badge
                        key={index}
                        w={100}
                        onClick={() => {
                            context.changeSelectedValue(item);
                            if (props.onChange)
                                props.onChange(state.model, item);
                        }}
                        variant={buttonStyles[parseInt(state.model.column?.styleVariant!)] || undefined}
                        size={state.model.column?.styleSize ? sizes[parseInt(state.model.column.styleSize!)] : 'sm'}
                        radius={state.model.column?.styleRadius || 0}
                        className='LY_StaticDropDownDisplayBadge'
                        style={{
                            color: text_color,
                            backgroundColor: item?.color || "#f1f3f5",
                            height: '20px',
                            fontSize: state.model?.column?.fontSize || '14px',
                            width: '100%!important',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {item?.name}
                    </Badge>
                }



                return <Badge
                    onClick={(e: any) => {
                        context.changeSelectedValue(item);
                        if (props.onChange)
                            props.onChange(state.model, item);
                    }}
                    key={item.id}
                    w={100}
                    variant={isHoveredId === item.id && buttonStyles[parseInt(state.model.column?.styleVariant!)] || undefined}
                    size={isHoveredId === item.id && state.model.column?.styleSize ? sizes[parseInt(state.model.column.styleSize!)] : 'sm'}
                    radius={isHoveredId === item.id && state.model.column?.styleRadius || 0}
                    className='LY_StaticDropDownDisplayBadge'
                    onMouseEnter={() => handleMouseEnter(item.id!)}
                    onMouseLeave={() => handleMouseLeave('')}
                    style={{
                        color: isHoveredId === item.id ? item.text_color || "#515152" : '#000',
                        backgroundColor: isHoveredId === item.id ? item.color || "#f7e4fd" : '#fff',
                        height: '20px',
                        fontSize: state.model?.column?.fontSize || '14px',
                        justifyContent: 'flex-start',
                    }}
                >
                    {item?.name}
                </Badge>

            })}
        </div>

        <LY_StaticDropDown_Footer {...props} />

    </>
}
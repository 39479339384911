import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';

import * as Api from 'app-api';

import '../ListTableView.css';
import { Badge, Tooltip } from '@mantine/core';

interface ListTableViewTableCellRendererProps {
  state: App.ListState;

  model: LY.DataItemModel;
  forceReadOnly: boolean;
  disableNameClick?: boolean;
  onChange?: (model: LY.DataItemModel | undefined, event?: React.ChangeEventHandler | undefined) => void;
  forceUpdateState?: (state?:App.ListState|undefined) => void;
}

export const ListTableViewTableCellRenderer: React.FC<ListTableViewTableCellRendererProps> = ((
  {
    state,
    /*  row,
     col,
     value,
     rowId,
     viewId, */
    model,
    onChange,
    forceReadOnly,
    ...props

  }) => {

  const forceUpdate = LIB.useForceUpdate();
  const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null);
  const context = React.useContext(LY.ListTableViewContext);

  const workspace = App.AppBase.currentWorkspace;

  /* 
    function onBtnSaveClick() {
      console.log('ListTableViewTableCellRenderer onNewItemSave');
  
      newItem.current?.sendUpdates();
  
     
  
  
    }
   */



  function onLocalChange(m: LY.DataItemModel | undefined, e: any) {

    // console.log('ListTableViewTableCellRenderer LY_TagsInput onLocalChange m: ',m);
    //console.log('ListTableViewTableCellRenderer onLocalChange model: ',model);
    // console.log('LY_NameColumnInput getViewMode model.value3: ',model?.value);

    if (!m)
      return

    model.value = m.value;

    //console.log('LY_NameColumnInput getViewMode model.value4: ',model?.value);
    //console.log('LY_NameColumnInput getViewMode model.value5: ',m?.value);


    if (onChange)
      onChange(model, e);

    //forceUpdate();
  }

  function getRelationshipItemClick(m: LY.DataItemModel | undefined, event: React.MouseEvent<any> | undefined) {

    console.log('ListTableViewTableCellRenderer getRelationshipItemClick m: ', m);


    var vm = state.vm;
    var col = m?.column;

    console.log('ListTableViewTableCellRenderer getRelationshipItemClick col: ', col);
    console.log('ListTableViewTableCellRenderer getRelationshipItemClick column name: ', col?.name);


    vm.setSelectedItem(m);
    vm.isDetailPopupModalOpen = true;

    if(col?.name=='name'){
      vm.selectedDetailListId = col?.listId;
      vm.selectedDetailViewId = state.list?.detailViewId || state.view?.listViewId;
    }
    else{
      vm.selectedDetailListId = col?.fkListId;
      vm.selectedDetailViewId = col?.fkListViewId;
    }




    App.AppStore.dispatch(App.ActionTypes.LIST_DATA_ITEM_VIEW_DETAIL_POPUP_CHANGE, vm);


    /*  console.log('LY_Relationship onItemClick');
     var detailUrl = getDetailUrl();
 
     console.log('LY_Relationship onItemClick URL: ',detailUrl);
     console.log('LY_Relationship onItemClick Model: ',model);
  */

  }




  function onNameClick(m: LY.DataItemModel | undefined, e: React.MouseEvent<HTMLElement> | undefined) {
    console.log('onNameClick model:', m);
    if (e)
      e.preventDefault();

    if (props.disableNameClick)
      return;

    var vm = state.vm;

    vm.setSelectedItem(m);
    vm.isDetailRightPanelOpen = true;
    //forceUpdate();

    //todo 
    App.AppStore.dispatch(App.ActionTypes.LIST_SETTINGS_DETAIL_PANEL_CHANGE, vm);


  }


  function render() {

    var col = model?.column;
    //console.log('ListTableViewTableCellRenderer model',model);
    //console.log('ListTableViewTableCellRenderer model.originalValue',model?.originalValue);

    if (model?.isEditMode) {
      console.log('ListTableViewTableCellRenderer col', col);
      console.log('ListTableViewTableCellRenderer model', model);

    }

    //console.log('ListTableViewTableCellRenderer linkedWorkspaceId', state?.listFilterSm?.linkedWorkspaceId);
    //console.log('ListTableViewTableCellRenderer state.linkedWorkspaceId', state?.linkedWorkspaceId);



    var readOnly = false;


    var linkedWorkspaceId = state?.linkedWorkspaceId;

    if (col.fkIsLinkedWorkspace && col.fkWorkspaceId)
      linkedWorkspaceId = col.fkWorkspaceId;


  
    var result = <LY.LA_InputSelector
      key={`formItem_${col.name}_${model?.rowId}`}
      model={model}
      workspaceId={state.workspaceId}
      linkedWorkspaceId={linkedWorkspaceId}
      column={col}
      lookupTypes={workspace?.lookupTypes}
      dropDownData={state.dropDownData}
      forceReadOnly={forceReadOnly}
      name={col.name}
      style={{ width: '100%', height: '100%' }}
      onClick={getRelationshipItemClick}
      onChange={onLocalChange}
      onNameClick={onNameClick}
      forceUpdateState={props.forceUpdateState}
      isForList={true}
    />


    return result;

  }




  return render();
});

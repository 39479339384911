import "./LY_ListFilterModule_Filter.css";

import * as Icons from "@tabler/icons-react";
import * as LY from '_LY_Components';

import { Button, Popover } from "@mantine/core";
import React, { useEffect, useState } from "react";

import LYFiltersModuleFilterGroup from "./components/LY_ListFilterModule_Filter_Group/LY_ListFilterModule_Filter_Group";
import LYFiltersModuleFilterRow from "./components/LY_ListFilterModule_Filter_Row/LY_ListFilterModule_Filter_Row";
import { LY_FiltersModule_Filter_Actions } from "./components/LY_ListFilterModule_Filter_Actions/LY_ListFilterModule_Filter_Actions";
import useIsMobile from "_LIB/Components/Hooks/UseIsMobile";

export const LY_FiltersModule_Filter: React.FC<LY.LY_ListFilterModuleProps> = (props) => {

  const context = LY.useListFilterModuleContext();
  const { view } = context.state;

  const [opened, setOpened] = useState(false);
  const [secondFilter, setSecondFilter] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  const handleScroll = (toBottom?: boolean) => {
    if (toBottom) {
      setScrollToBottom(true);
    } else {
      setScrollToTop(true);
    }
  };

  useEffect(() => {
    if (
      view?.filter?.conditionSet?.conditions &&
      view?.filter?.conditionSet?.conditions?.length > 1
    ) {
      setSecondFilter(true);
    } else {
      setSecondFilter(false);
    }
  }, [view?.filter?.conditionSet?.conditions]);

  const { isMobile } = useIsMobile(768);

  const getFilterCount = () => {
    const count1 = view?.filter?.conditionSet?.conditions?.length || 0;
    const count2 = view?.filter?.conditionSets?.length || 0;
    return count1 + count2;
  }

  function comboboxTargetAdjustmentRenderer() {
    if (isMobile) {
      return (
        <Icons.IconAdjustmentsHorizontal
          width={36}
          height={36}
          stroke={1.5}
          color="#757B87"
          className="LY_ListFiltersModule_Filter_button_Mobile"
          onClick={() => setOpened(true)}
        />
      );
    }

    return (
      <Button
        onClick={() => setOpened(true)}
        className={`LY_ListFiltersModule_Filter_button ${getFilterCount() > 0 && 'LY_ListFiltersModule_Filter_button_active'}`}
        variant="transparent"
        leftSection={
          <Icons.IconAdjustmentsHorizontal
            width={16}
            height={16}
            stroke={1.5}
          />
        }
      >
        Filter {!!getFilterCount() && <span className="LY_ListFiltersModule_Filter_count">{getFilterCount()}</span>}
      </Button>
    );
  }

  function filterRowRenderer() {
    return view.filter?.conditionSet?.conditions?.map((condition, index) => {
      return (
        <LYFiltersModuleFilterRow
          scrollToTop={scrollToTop}
          setScrollToTop={setScrollToTop}
          key={index}
          isConditionSet={true}
          rowIndex={index}
          isFirstCondition={index === 0}
          condition={condition}
          isDeletable
          withDisabledOperations={index !== 1}
          joinOperator={view.filter?.conditionSetsJoinOperator}
          {...props}
        />
      );
    })
  }

  function filterGroupRenderer() {
    return view.filter?.conditionSets?.map(
      ({ conditions, joinOperator }, index) => {
        return (
          <LYFiltersModuleFilterGroup
            key={index}
            scrollToBottom={scrollToBottom}
            setScrollToBottom={setScrollToBottom}
            groupIndex={index}
            joinOperator={view.filter?.conditionSetsJoinOperator}
            groupJoinOperator={joinOperator}
            conditions={conditions}
            disabledJoinOperation={index > 0 || secondFilter}
          />
        );
      }
    )
  }

  function comboboxDropDownRenderer() {
    return (
      <Popover.Dropdown
        className="LY_ListFiltersModule_Filter_comboboxDropdown"
        classNames={{
          arrow: 'LY_PopoverRoundCorners-arrow',
          dropdown: 'LY_PopoverRoundCorners'
        }}
      >
        <div className="LY_ListFiltersModule_Filter_header">Advanced Filters</div>
        <div className="LY_ListFiltersModule_Filter_body LY_Scrollbar">
          {filterRowRenderer()}
          {filterGroupRenderer()}
        </div>
        <LY_FiltersModule_Filter_Actions handleScroll={handleScroll} setOpened={setOpened} />
      </Popover.Dropdown>
    );
  }

  return (
    <Popover
      width={740}
      position="bottom"
      withinPortal={true}
      opened={opened}
      withArrow
      onChange={setOpened}
      closeOnClickOutside={false}
    >
      <Popover.Target>
        {comboboxTargetAdjustmentRenderer()}
      </Popover.Target>
      {comboboxDropDownRenderer()}
    </Popover>
  );
};

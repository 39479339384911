import "./LY_StaticDropDown_View.css"

import * as Api from 'app-api'
import * as Icons from '@tabler/icons-react';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { Badge, Tooltip } from '@mantine/core';

import React from 'react';

interface LY_StaticDropDown_ViewProps extends LY.LY_StaticDropDownProps { }

export const LY_StaticDropDown_View = React.forwardRef<HTMLDivElement, LY_StaticDropDown_ViewProps>((
    props, ref
) => {


    const context = LY.useStaticDropDownContext();
    const state = context.state;

    const variant = state?.model?.column?.styleVariant;
    const badgeFontSize = state?.model?.column?.fontSize || '14px';
    const badgeRadius = state?.model?.column?.styleRadius || 0;
    const displayedItems = (state?.model?.column?.styleIsFullWidth && state.allowMultipleSelection) ? state?.selectedItems?.slice(0, 1) : state?.selectedItems && state?.selectedItems?.length > 3 ? state?.selectedItems?.slice(0, 3) : state?.selectedItems;
    return <div
        onClick={() => context.setPopoverOpened(true)}
        ref={ref}
        className='LY_StaticDropDownDisplay'
    >
        {displayedItems?.map(selectedItem => {
            const badgeColor = selectedItem?.text_color || props?.model?.column?.textColor || '#000';
            const badgeBackgroundColor = variant === 'transparent' ? '' : selectedItem?.color || props?.model?.column?.valueBackgroundColor || "#f1f3f5";
            return (
            /*     <Tooltip label={selectedItem?.name} key={selectedItem?.id} > */
                    <Badge
                        radius={badgeRadius}
                        className='LY_StaticDropDownDisplayBadgeView'
                        style={{
                            width: !state?.model?.column?.styleIsFullWidth ? 'fit-content' : '100%',
                            border: variant === 'transparent' ? '' : '1px solid!important',
                            borderColor: variant !== 'transparent' ? '' : `${selectedItem?.color}!important`,
                            fontSize: badgeFontSize,
                            color: badgeColor,
                            backgroundColor: variant === 'outline' ? '' : badgeBackgroundColor,
                            cursor: 'pointer',
                            userSelect: 'none',
                            justifyContent: state.allowMultipleSelection ? 'flex-start!important' : 'space-between!important',

                        }}
                        variant={variant || 'filled'}
                        rightSection={state.allowMultipleSelection && <Icons.IconX
                            width={12}
                            height={12}
                            color={badgeColor}
                            onClick={() => {
                                if (state.selectedItems) {
                                    state.selectedItems = state.selectedItems.filter(i => i.id !== selectedItem.id);
                                    context.forceUpdate();
                                }
                            }}
                        />
                        }
                    >
                        {selectedItem?.name}
                    </Badge>
              /*   </Tooltip> */
            );
        })}
        {state?.selectedItems && state?.selectedItems?.length > 3 && !state?.model?.column?.styleIsFullWidth &&
            <Badge
                radius={badgeRadius}
                style={{
                    backgroundColor: "#f1f3f5",
                    color: "#000",
                    fontWeight: 500,
                    width: '120px',
                    fontSize: badgeFontSize,
                    cursor: 'pointer',
                    userSelect: 'none',
                }}
                variant={variant || 'filled'}
            >
                +{state.selectedItems?.length - 3}
            </Badge>}
    </div >
});

export const buttonStyles = [
    'Filled', 'Outline', 'Transparent'
];
export const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

export const valuePropertyNames = ['id', 'value', 'value_id'];

export const randomColor = [
    "#f3f3fe",
    "#e4e6ed",
    "#c8cad3",
    "#a9adb9",
    "#9093a4",
    "#808496",
    "#767c91",
    "#656a7e",
    "#585e72",
    "#4a5167",
    "#fff0e4",
    "#ffe0cf",
    "#fac0a1",
    "#f69e6e",
    "#f28043",
    "#f06d27",
    "#f06418",
    "#d6530c",
    "#bf4906",
    "#a73c00",
    "#f3edff",
    "#e0d7fa",
    "#beabf0",
    "#9a7ce6",
    "#7c56de",
    "#683dd9",
    "#5f2fd8",
    "#4f23c0",
    "#451eac",
    "#3a1899",
    "#ffe9e9",
    "#ffd1d1",
    "#fba0a1",
    "#f76d6d",
    "#f34141",
    "#f22625",
    "#f21616",
    "#d8070b",
    "#c10008",
    "#a90003",
    "#fdfce5",
    "#f8f6d3",
    "#f0ecaa",
    "#e7e17c",
    "#e0d957",
    "#dbd33e",
    "#d9d02f",
    "#c0b820",
    "#aaa316",
    "#938c03"
];
export const getRandomColor = (): string => {
    const randomIndex = Math.floor(Math.random() * randomColor.length);
    return randomColor[randomIndex];
};

export const radiusValues = [
    { value: 0 },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
]
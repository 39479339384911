
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { ComboboxOptionProps } from '@mantine/core';


export class DataItemModel extends LIB.BaseVmModel {

    public name: string;
    public viewId:number;
    public workspaceId:number;
    public value?: any;
    public originalValue?: any;
    public rowId?:any;
    public listId?:any;
    public groupId?:number;
    public loading:boolean=false;
    public editing:boolean=false;
    public open:boolean=false;


    public column: Api.ListColumn;
   
    public row:any;

    public isEditMode: boolean = false;

    public valueChanged: boolean = false;

    constructor(workspaceId?:number, rowId?:any, viewId?:number, name?:string, value?:any, originalValue?:any, groupId?:number) {

        super();
        if(workspaceId)
        this.workspaceId = workspaceId;

        if(rowId)
            this.rowId = rowId;

        if(name)
        this.name = name;

        this.value = value;

        if(viewId)
        this.viewId = viewId;

        this.originalValue = originalValue;
        this.groupId = groupId;

    }


    
    

}
import React, { ReactElement, useImperativeHandle, forwardRef, FocusEventHandler } from 'react';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as MT from '@mantine/core';

import './LY_TextInput.css';
import * as Icons from '@mui/icons-material/';
import * as LY from '_LY_Components';
import { CircularProgress } from '@mui/material';


interface LY_TextInputProps {
  name: string;
  model?: LY.DataItemModel;
  inputRef?: any;
  viewId?: number;
  value?: string;
  originalValue?: string;
  rowId?: any;
  preItemIcon?: any;
  onDeleteKeyPress?: Function;
  required?: boolean;
  type?: string;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange?: Function;
  onEnter?: Function;
  onEditingChange?: Function;
  onChangesDone?: Function;
  placeholder?: string;
  clearOnBlur?: boolean;
  onEnterBlur?: boolean;
  autoFocus?: boolean;
  onInputBlur?: FocusEventHandler<any> | undefined; 
  tooltip?: string;
}

export type  LY_TextInputHandle = {
  sendUpdates: () => void;
}
 

export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((
  {
    name,
    viewId,
    rowId,
    model = new LY.DataItemModel(),
    clearOnBlur = false,
    inputRef,
    inputStyle,
    style,
    required = false,
    type = 'text',
    inputClassName,
    onChange,
    onEnter,
    onEditingChange,
    onChangesDone,
    placeholder,
    value,
    originalValue,
    onEnterBlur,
    autoFocus,
    ...props
  
  }
  , ref) => {


    useImperativeHandle(ref, () => ({
      sendUpdates: () => {
        // Implement the sendUpdates method here...

        sendUpdates({},true);
      },
    }));
  

  /*   if (model == undefined)
      model = new LY.DataItemModel(); */

   if(value)
       value = LIB.StringHelper.getCleanValueForInputType(value, type);  

   if (value && model.value == undefined){
    //console.log('LY_TextInput useEffect model value1', model?.value);
    model.value = value;
 
  }

  //if (originalValue && model.originalValue == undefined)
  //  model.originalValue = originalValue;

  if (name)
    model.name = name;

  if (viewId)
    model.viewId = viewId;

  if (rowId)
    model.rowId = rowId;


    model.value = LIB.StringHelper.getCleanValueForInputType(model?.value, type);  
    model.originalValue = LIB.StringHelper.getCleanValueForInputType(model?.originalValue, type);  

   /*  if(type=='date'){
      console.log('LY_TextInput useEffect model Bend', model?.value, type);
   
    } */


  //  const [imodel, setIModel] = React.useState<LY.DataItemModel>(model);
   const forceUpdate = LIB.useForceUpdate();

   const [ itemValue, setItemValue ] = React.useState<any>(''); // Add this line
   const [ valueChanged, setValueChanged ] = React.useState(false); // Add this line

  
    React.useEffect(() => {
     //console.log('LY_TextInput useEffect model value', model?.value);

   /*   if(type=='date'){
      console.log('LY_TextInput useEffect model Bend2', model?.value, type);
   
    } */

    var val = model?.value;
    if(val==undefined)
      val = '';

    setItemValue(val);


    /* if(type=='time' && model?.rowId=='6baeb63e-4a8e-4aa7-a2af-4bbdf005ec14'){
      console.log('LY_TextInput useEffect time value:', value, model?.value);
      console.log('LY_TextInput useEffect time model:',model);

    } 
 */

  }, [ model.value ]);   


   React.useEffect(() => {

    var val = value;
    if(val==undefined)
      val = '';
    setItemValue(val);

  }, [ value ]); 



  function onBlurCapture(e: React.FocusEvent<HTMLInputElement>) {
    // e.preventDefault();

     console.log('LY_TextInput onInputBlur originalValue:', model?.originalValue);
   // console.log('LY_TextInput onInputBlur value:', model?.value);
    model.value = e.target.value;
    model.editing = false;

/*     if (model?.value === model?.originalValue && !clearOnBlur
      //&& !LIB.Common.isNullOrEmpty(model?.value)
      )
      {
      return;
    } */
 //   if(!clearOnBlur)
   //   return;

    //console.log('LY_TextInput onInputBlur send updates:', model);

   
    sendUpdates(e); 
  
  }


  function checkHasValidData(e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>): boolean {
    // e.preventDefault();

   // console.log('LY_TextInput checkUpdateData model:', model);
   // console.log('LY_TextInput checkUpdateData value:', e.currentTarget.value);

    model.value = e.currentTarget.value;

    return true;
  }


  function sendUpdates(e: any, isEnter: boolean = false) {

    model.valueChanged = valueChanged;

    if (onEnter && !valueChanged && !clearOnBlur) {
       console.log('LY_TextInput onEnter isEnter valueChanged:', model.valueChanged);
       onEnter(model, e);
     }

    if(!valueChanged)
      return;

     console.log('LY_TextInput sendUpdates valueChanged:', valueChanged);


    setTimeout(() => {
      changeEditing(false);
    }, 300);

    var isValid = checkHasValidData(e);

    if (!isValid)
      return
 
     //console.log('LY_TextInput sendUpdates isEnter:', isEnter);
    // console.log('LY_TextInput sendUpdates onEnter:', onEnter);
     //console.log('LY_TextInput sendUpdates onChange:', onChange);


    if (onChange && !isEnter) {
       console.log('LY_TextInput onChange sendUpdates model:', model);

      onChange(model, e);
      model.loading = true;
 
      forceUpdate();
    }
    else if (onEnter && isEnter) {

      console.log('LY_TextInput onEnter sendUpdates model:', model);

      onEnter(model, e);
      model.loading = true;
      forceUpdate();

    }
    else if(onChangesDone){

      console.log('LY_TextInput sendUpdates onChangesDone:', onChangesDone);

      onChangesDone(model, e);
      model.loading = true;
      forceUpdate();
    }

    /*  if(clearOnBlur && (onChange || onEnter) ){
       console.log('LY_TextInput clearOnBlur clear out:',model);
        model.value = '';
        setItemValue(model.value);
       //setIModel(imodel);
      } */

    setValueChanged(false);
  }

  function changeEditing(newVal:boolean){

    console.log('LY_TextInput onEditingChange newVal:', newVal);

    if(model.editing==newVal)
      return;

    model.editing = newVal;

    if(onEditingChange){
      //console.log('LY_TextInput onEditingChange:', model);
      onEditingChange(model);

    }

  }

  function onInputChange(e: React.FocusEvent<HTMLInputElement>) {
    //e.preventDefault();
    // console.log('LY_TextInput onInputChange:', e.target);
    // console.log('LY_TextInput onInputChange model:', model);
     //console.log('LY_TextInput onInputChange value:', e.target.value);
     //console.log('LY_TextInput onInputChange currentTarget value:', e.currentTarget.value);

    model.originalValue = model.value;
    model.value = e.target.value;

    
    setItemValue(e.target.value);

    setValueChanged(true);

    //console.log('LY_TextInput onInputChange model2:', model);
    //forceUpdate();

    //setIModel(imodel);

  }

  
  function onFocusChange(e: React.FocusEvent<HTMLInputElement>) {
    //e.preventDefault();
    // console.log('LY_TextInput onInputChange:', e.target);
    // console.log('LY_TextInput onInputChange model:', model);
    //console.log('LY_TextInput onInputChange value:', e.target.value);
    changeEditing(true);

    
    
 
  }


  function onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    //e.preventDefault();
    //  console.log('LY_TextInput onKeyPress key:', e.key);
    if (e.key == 'Enter' || e.key == 'Tab') {
       console.log('LY_TextInput onKeyPress Enter:', model);

      if (onEnterBlur)
        e.currentTarget.blur();

      else //On enter or tab
        sendUpdates(e, true);

      //inputRef.current?.blur();
    }

  }
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    //e.preventDefault();
       // console.log('LY_TextInput onKeyDown key:', e.key);
       // console.log('LY_TextInput onKeyDown e:', e);

    if (e.key == ' ' || e.ctrlKey || e.altKey || e.shiftKey || e.metaKey ||  
      (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight')
    ) {
       //  console.log('LY_TextInput onKeyDown SPACE...:');

      e.stopPropagation();
     // e.preventDefault();
    }

  }

  function onLocalBlur(e: React.FocusEvent<HTMLInputElement>) {


  }

  function render() {

   /*  if (clearOnBlur) {

      console.log('LY_TextInput render model:', model);
      console.log('LY_TextInput render itemValue:', itemValue);

    }
 */
   /*  console.log('LY_TextInput render value:', value);
    console.log('LY_TextInput render originalValue:', originalValue);
    console.log('LY_TextInput render model.originalValue:', model.originalValue);
 */
    if (type == undefined)
      type = 'text';

    if (inputClassName == undefined)
      inputClassName = 'LY_TextInput';


     /*  if(type=='date'){
        //console.log('LY_TextInput render date value:', value, model?.value);
      } */
     /*  if(type=='time' && model?.rowId=='6baeb63e-4a8e-4aa7-a2af-4bbdf005ec14'){
        console.log('LY_TextInput render time value:', value, model?.value);
        console.log('LY_TextInput render time model:',model);

      }  */

    return (
      <div key={name}
        
        className='LY_TextInputContainer' style={style}>

        <input
          className={inputClassName}
          ref={inputRef}
          type={type} 
          required={required}
          onBlur={props.onInputBlur}
           onBlurCapture={onBlurCapture}
          onFocus={onFocusChange}
          //onInput={onInputChange}
          onChange={onInputChange}
          value={itemValue}
          // defaultValue={imodel.value}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyPress}
          placeholder={placeholder}
          style={inputStyle}
          disabled={model.loading}
          autoFocus={autoFocus}
         // checked={type == 'checkbox' ? Boolean(itemValue) : undefined}
        />

      </div>


    );

  }

  function getChildrenWithTooltip(){

    var view = render();
    var tooltip = props.tooltip || model?.column?.helpText;
 

    if (tooltip && React.isValidElement(view)) {
      return <MT.Tooltip label={tooltip} style={{maxWidth: '220px', textWrap: 'wrap'}}
        // w={220}
        withArrow
        transitionProps={{ duration: 200 }}
        position='bottom'
        events={{ hover: true, focus: true, touch: false }}
        withinPortal={true}
      >
        {view}
      </MT.Tooltip>
    } 
    return view;
 
  }

//  return render();
  return getChildrenWithTooltip();


}
);
import "./LY_StatusDropDown_Styles.css"

import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Badge, Box, Select, Slider } from '@mantine/core';
import { buttonStyles, radiusValues, sizes } from "../../Constants/constants";

import React from 'react';
import { StringHelper } from "_LIB";

export const LY_StatusDropDown_Styles: React.FC = () => {

    const context = LY.useStatusDropDownContext();
    const state = context.state;



    function setStyleVariant(value: string | null) {
        const styleVariant = { ...state?.model?.column, styleVariant: value!?.toLowerCase() }
        context.setInitialColumns(styleVariant)
    }


    function styleVariantRenderer() {



        return <Select
            classNames={{
                input: 'LY_StatusDropdown_Styles_select_input',
                label: 'LY_StatusDropdown_Styles_select_label'
            }}
            comboboxProps={{ withinPortal: false }}
            label="Style"
            value={state?.model?.column?.styleVariant ? StringHelper.capitalizeFirstLetter(state?.model?.column?.styleVariant) : 'Default'}
            onChange={setStyleVariant}
            data={buttonStyles}
            style={{ marginBottom: 20 }}
            onSelect={(e) => context.setInitialColumns({ ...state?.model?.column, styleVariant: e.currentTarget.value })}
            checkIconPosition="right"
        />
    }



    function styleRadiusRenderer() {
        return <div className="LY_StatusDropdown_Styles_Radius_Wrapper">
            <div>Radius</div>
            <Slider
                step={1}
                value={state?.model?.column?.styleRadius ? parseInt(state?.model?.column?.styleRadius) : 0}
                onChange={(size) => {
                    context.setInitialColumns({ ...state?.model?.column, styleRadius: size.toString() })
                }}
                min={0}
                max={10}
                style={{ marginTop: 10 }}
                marks={radiusValues}
            />
        </div>
    }



    function buttonRenderer() {
        return <div className="LY_StatusDropdown_Styles_Button">
            <Badge
                variant={state?.model?.column?.styleVariant || "default"}
                size={state?.model?.column?.styleSize ? sizes[parseInt(state.model.column.styleSize)] : 'xl'}
                radius={state?.model?.column?.styleRadius || 0}
            >
                Button
            </Badge>
        </div>
    }



    function render() {
        return (
            <Box className="LY_StatusDropdown_Styles_Wrapper">
                {styleVariantRenderer()}
                {styleRadiusRenderer()}
                {buttonRenderer()}
            </Box>
        );
    }


    return render();

}

export default LY_StatusDropDown_Styles;

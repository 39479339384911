import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { CSSProperties } from "react";

import './WorkspaceDropdown.css';


import { WorkspaceDropdownContextMenu } from './WorkspaceDropdownContextMenu/WorkspaceDropdownContextMenu';


interface WorkspaceDropdownProps {
    state: App.GlobalState;


}

export const WorkspaceDropdown: React.FC<WorkspaceDropdownProps> = (props) => {



    var selectedTab: string | undefined;
    var params = useParams();
    var location = useLocation();
    var locationState = location.state;


    /*   React.useEffect(() => {
          console.log("WorkspaceDropdown useEffect update");
  
      }); */


    var state = props.state;
    var globalVm = state.globalUIState;
    var vm = state.workspaceVm;

    var selectedItem = App.AppBase.location?.pathname;
    var currentUser = App.AppBase.currentUser;

    var selectedWorkspace = vm?.selectedWorkspace



    function onSelectedItemsChange(name: string, selectedValue: number) {
        console.log('onSelectedItemsChange name', name);
        console.log('onSelectedItemsChange selectedValue', selectedValue);

        var sm = new Api.Workspace();
        sm.workspaceId = selectedValue;
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_REQUEST, sm);

    };


    function render() {

        if (!selectedWorkspace)
            return null

        return <div  >

            {/*    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                <LY.LY_StatusSelect

                    label="Workspace"
                    name="workspace"
                    data={vm.workspaces}
                    labelProperty="name"
                    valueProperty='workspaceId'

                    value={vm.selectedWorkspace || ''}
                    //variant="standard"
                    // textFieldStyle={textFieldStyle}
                    // textFieldStyle={{   width: '100%' }}
                    //  textFieldClassName='WorkspaceMenuTextField'
                    tag='CRM'
                />

                <MT.Button
                    color="primary"
                    variant="contained"
                    style={{ padding: 2, minWidth: 10 }}
                //onClick={this.onSaveClick.bind(this)}
                >   <Icons.AddOutlined />          </MT.Button>

            </div> */}

            <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                <App.FormAutocomplete
                    label="Workspace"
                    name="workspace"
                    multiple={false}
                    disableClearable={true}
                    data={vm.workspaces}
                    onChange={onSelectedItemsChange}
                    labelProperty="name"
                    valueProperty='workspaceId'
                    // labelRenderer= {(item: any) => { return `${item?.fullName} (${item?.email})`;} }
                    value={vm.selectedWorkspace || ''}
                    //variant="standard"
                    // textFieldStyle={textFieldStyle}
                    textFieldStyle={{ width: '100%' }}
                    textFieldClassName='WorkspaceMenuTextField'
                />
                <WorkspaceDropdownContextMenu state={props.state} />

            </div>




        </div>;

    }


    return render();
} 
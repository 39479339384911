import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import * as LY from '_LY_Components';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";

import { useParams, useLocation } from 'react-router';

import { ListTableViewTable } from './components/ListTableViewTable';
import { AddColumnScreen } from '../Columns/AddColumnScreen/AddColumnScreen';
import { UpdateColumnScreen } from '../Columns/UpdateColumnScreen/UpdateColumnScreen';

import { ListFilter } from '../ListFilter/ListFilter';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ResizeHandle from 'Components/Layouts/MainSplitPage/ResizeHandle';
import { DetailRightPanel } from 'Pages/Detail/DetailRightPanel/DetailRightPanel';
import { SelectedItemActions } from '../SelectedItemActions/SelectedItemActions';
import { DetailPopupModal } from 'Pages/Detail/DetailPopupModal/DetailPopupModal';
import { LY_ListFilterModule } from '_LY_Components/_Modules/LY_ListFilterModule/LY_ListFilterModule';




//class ListTableView extends React.Component<App.CMSState, {}> {
export const ListTableView: React.FC<LY.ListTableViewProps> = (props) => {


  var validationManager: App.ValidationManager = new App.ValidationManager();

  var params = useParams();
  var location = useLocation();

  let initialListState = new App.ListState();
  initialListState.forceReadOnly = props.forceReadOnly || false;
  initialListState.hideCheckboxSelection = props.hideCheckboxSelection || false;

  const [ listState, setListState ] = React.useState(initialListState);
  const forceUpdate = LIB.useForceUpdate();
  listState.listFilterSm.linkedWorkspaceId = props.linkedWorkspaceId;
  listState.linkedWorkspaceId = props.linkedWorkspaceId;
  listState.workspaceId = props.workspaceId!;

  const workspace = App.AppBase.currentWorkspace;

  var listTableManager: LY.ListTableManager = new LY.ListTableManager(props, listState, forceUpdateState,
    onListDataLoaded, onListDataChanged);

  React.useEffect(() => {


   /*  console.log('ListTableView useEffect filter:', props.filter);
    console.log('ListTableView useEffect filter: forceFilter:', props.forceFilter);
 */

    /*     console.log('ListTableView useEffect viewId:', props.viewId);
        console.log('ListTableView useEffect vm:', listState.vm);
        console.log('ListTableView workspaceId', props.workspaceId);
        console.log('ListTableView linkedWorkspaceId', props.linkedWorkspaceId); */
    //console.log('ListTableView forceReadOnly', props.forceReadOnly); 
    //console.log('ListTableView listState.forceReadOnly',  listState.forceReadOnly); 

    onRefreshData();

  }, [ props.viewId ]);


  function forceUpdateState(st:App.ListState|undefined=undefined){
    var vm = listState.vm;

    console.log('ListTableView forceUpdateState isActionInProgress', vm.isActionInProgress);
    console.log('ListTableView forceUpdateState state', st);

    if(st?.workspace?.lookupTypes && workspace){
      console.log('ListTableView forceUpdateState lookupTypes', st?.workspace?.lookupTypes);
      workspace.lookupTypes = st.workspace.lookupTypes;
    
    }

    setListState(listState);

    forceUpdate();
  }

/*   function onForceUpdate(state: App.ListState) {

    if (state?.workspace?.lookupTypes) {
      listState.workspace.lookupTypes = state.workspace.lookupTypes;

    }
    setListState(listState);
    forceUpdate();
  } */


  function onListDataLoaded(listState: App.ListState) {
  //  console.log('onListDataLoaded listState', listState);
   // console.log('onListDataLoaded filter', listState?.view?.filter);
   // console.log('onListDataLoaded filter.selectColumns', listState?.view?.filter?.selectColumns);

    setListState(listState);
     

    if (props.onListDataLoaded)
      props.onListDataLoaded(listState);


  }
  function onListDataChanged(newState: App.ListState) {
    console.log('onListDataChanged listState', newState); 
  
    listState.records = [...newState.records];
    listState.newItem = newState.newItem;
    setListState(listState);
   
  }


  function onRefreshData() {
    console.log('onRefreshData');

    if(props.forceFilter)
      listTableManager.getListData(undefined, props.filter);
    else
      listTableManager.getListData();

  }


  function onCreateClick() {
    console.log('onCreateClick');
    var state = listState;
    var vm = state.vm;

    // vm.setSelectedPage();

    // App.RouteManager.routeToPath(`/Pages/0/`);

    //vm.isPagesAddOpen = true;
    forceUpdate();


  }
 

 

  function render() {

    console.log('ListTableView render');

    var state = listState;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    //var filter = state.listFilter;

    //console.log('ListTableView hasPageDataLoaded', state.hasPageDataLoaded);
    // console.log('ListTableView isServiceCallPending',myListingsService.isServiceCallPending);
    //console.log('ListTableView vm.isActionInProgress 1', vm.isActionInProgress);

    var list = state.list;
    var view = state.view;


    //view.filter = state?.listFilter;

    console.log('ListTableView render view.filter', view.filter);

    var showSave = (currentUser?.isAdmin || App.AppBase.isDev()) && !state.forceReadOnly;

    if(!showSave && currentUser?.email?.toLowerCase()?.endsWith('loocey.com'))
      showSave = true;

    var createBtn: any = null;

    /*   if (currentUser.hasRole(App.UserRoleTypeEnum.PermitsAdmin)) { */
    /*     createBtn = <MT.Button
          id="btnCreate"
          variant="contained"
          style={{ margin: 5, padding: 2, minWidth: 10 }}
          color="primary"
    
          onClick={() => onCreateClick()}
        ><Icons.Add /></MT.Button> */
    /* } */

    //console.log('ListTableView render view.filter', view?.filter);
   // console.log('ListTableView render filter2', filter);

    var hide = state.forceReadOnly;

    console.log('ListTableView render vm.isActionInProgress2', vm.isActionInProgress);


    return <App.MainContainer

      errorMessage={vm.apiErrorMessage}
      loading={vm.isActionInProgress}
      hasDataLoaded={state.hasDataLoaded}
      showContentWhenInProgress={true}
      showModalForLoading={true}

    >
            <LIB.Loading show={vm.isActionInProgress} isModal={true} />
      <PanelGroup direction='horizontal'
        className={`ListPageContentPanelGroup ${props.groupPanelClass || ''}`}
      >
        <Panel className='ListPageContent' defaultSize={50}>

          <div>

            {props.showFilters && (

              /*  <ListFilter state={state}
                 onSearchClick={(searchFilter) => listTableManager.getListData(undefined, searchFilter)}
                 
               /> */

              <LY_ListFilterModule
 
                view={view}
                columns={state.view.columns}
                onApply={(newView:Api.ListView) => {
                  console.log('LY_FiltersModule onApply newView:', newView);
                  console.log('LY_FiltersModule onApply newView.filter:', newView.filter);

                 // listTableManager.getListData()
                  listTableManager.getListData(undefined, newView.filter)
                }}
                onColumnSelectionChange={(newView:Api.ListView) => {
                  console.log('LY_FiltersModule onColumnSelectionChange newView:', newView);
                  
                      var sm = new Api.ListGetDataSm();
                      sm.viewId = newView.listViewId;
                      sm.listId = newView.listId;
                      sm.linkedWorkspaceId = props.linkedWorkspaceId;
                      sm.filter = newView.filter;
                   listTableManager.getListData(sm, newView.filter,false)
                 }}

                /*  onApplyClientSide={(newView:Api.ListView) => {
                  
                   onListDataLoaded(listState);

                 }} */
                
                onSaveAsNewViewSuccess={(newView:Api.ListView) => {
                  console.log('LY_FiltersModule onSaveAsNewViewSuccess newView:', newView);
                  var list = state.list;
                 
                  list.listViews!.push(newView);

                  console.log('LY_FiltersModule onSaveAsNewViewSuccess listViews:', list.listViews);

                  forceUpdate();
                  if(props.onListDataLoaded)
                    props.onListDataLoaded(listState);
                }}

                hideSave={!showSave} //**hideSave
               // hideColumnSelection={hide}
                hideFilters={true}
               // hideSort={true}

              />
            )}

            <ListTableViewTable
              state={state}
              onForceUpdate={() => {
                console.log('ListTableViewTable onForceUpdate');
                forceUpdate()
              }}
              listTableManager={listTableManager}
              hideFooterWhenNoPaging={props.hideFooterWhenNoPaging}
              disableNameClick={props.disableNameClick}
              refreshData={onRefreshData}
              presetRelColumnName={props.presetRelColumnName}
              presetRelColumnValue={props.presetRelColumnValue}
              onSelectedItemsChange={(selectedItems: any[]) => {
                state.selectedItems = selectedItems; forceUpdate();
              }}

              tableHeight={props.tableHeight}
              minTableHeight={props.minTableHeight}
              leftPinnedColumns={props.leftPinnedColumns}
              forceUpdateState={forceUpdateState}
            />

            <AddColumnScreen state={state}
              refreshData={onRefreshData}
            />

            <UpdateColumnScreen state={state}
              refreshData={onRefreshData}
              onClose={() => { vm.isColumnSettingsScreenOpen = false; forceUpdate(); }}
            />



            <DetailPopupModal 
            state={state}
             model={vm.selectedItem} 

             />

          </div>

        </Panel>

        {vm.isDetailRightPanelOpen && (
          <>
            <ResizeHandle />
            <Panel id='mainRightPanel'
              className='ListRightPanelContent'
              collapsedSize={0}
              defaultValue={10}
              minSize={10}
              order={3}
              collapsible>

              <DetailRightPanel state={state}
                model={vm.selectedItem}
              />

            </Panel>
          </>
        )}



      </PanelGroup>


      <SelectedItemActions
        listId={state.listId}
        selectedItems={state.selectedItems}
        refreshData={onRefreshData}
        forceReadOnly={state.forceReadOnly}
        onClose={() => { state.selectedItems = []; forceUpdate(); }}
        linkedWorkspaceId={props.linkedWorkspaceId}

      />


    </App.MainContainer>
  }


  function renderWithProvider() {
    return (
      <LY.ListTableViewContextProvider>
        {render()}
      </LY.ListTableViewContextProvider>
    );

  }

  return renderWithProvider();
};


import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

export class LY_StatusDropDownState extends LIB.BaseVmModel {

    public isEditMode: boolean = false;
    public selectedItem: Api.SystemLookupType | undefined;
    public model: LY.DataItemModel = new LY.DataItemModel();
    public statuses: Api.SystemLookupType[] = [];
    public defaultStatuses: Api.SystemLookupType[] = [];
    public vm: LIB.BaseVmModel = new LIB.BaseVmModel();
    public newStatuses: Api.SystemLookupType[] = [];
    public isShowingAdvancedSettings = false;
    public isShowAdvancedSettingsDisabled = false;
    public isSelectStatusPopoverOpen = false;

    public chagnedItems: Api.SystemLookupType[] = [];


    constructor() {
        super();
    }
}

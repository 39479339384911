import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';


 
import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, ScrollArea } from '@mantine/core';
import { Tooltip, Tabs, rem } from '@mantine/core';

import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './_UpdateColumnScreen.css';
import { UpdateColumnAdvancedTab } from './UpdateColumnAdvancedTab';
import { UpdateColumnAdvancedTab_Calculations } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Calculations';

interface UpdateColumnScreenProps {
  state: App.ListState;
  refreshData: () => void;

  onClose?: () => void;

}

export const UpdateColumnScreen: React.FC<UpdateColumnScreenProps> = (props) => {

  const libForceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

/*   const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null); */

  const [ open, setOpen ] = React.useState(false);
  const currentWorkspace = App.AppBase.currentWorkspace;



  const { view, list, selectedColumn, columnTypes } = props.state;
  const columns = view?.columns;
  
  const [ vm, setVm ] = React.useState<LY.ListColumnUpdateVm>(new LY.ListColumnUpdateVm()); 

 
  const state = props.state;

  const updateColumnAdvancedTabRef = useRef<any>();


  useEffect(() => {
    //console.log('UpdateColumnScreen useEffect selectedColumn', selectedColumn);
    // vm.isActionInProgress=true;

    if(selectedColumn)
      onColumnChange(selectedColumn);

  }, [ selectedColumn ]);

  function forceUpdate( ) {

    var newVm = {...vm, errors: vm.errors};
 
    setVm(newVm);
    libForceUpdate();

  } 

  function onServiceError(error: any) {

   // console.error('UpdateColumnScreen onServiceError:', error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    vm.isActionInProgress = false;

   // console.error('UpdateColumnScreen onServiceError2 errorMessage:', errorMessage);

    vm.errors.set('all', errorMessage);
    
    forceUpdate();
  }


  function onColumnChange(item: Api.ListColumn) {

    //console.log('UpdateColumnScreen onColumnChange item:', item);
   
    if(!item || !item.listColumnId || !item.fkListId || !item.fkListColumnId){
      return;
    }
       
    var service = new App.ListColumnService(currentWorkspace?.workspaceId);
    service.getColumnInfoWithDetails(item.listColumnId,
      //onSuccess
      (result: any) => {
        //console.log('UpdateColumnScreen getColumnInfoWithDetails onSuccess:', result);

        LIB.ObjectHelper.setData(result,item);
        vm.isActionInProgress = false;
        forceUpdate();
      },
      //onError
      onServiceError
    );

    vm.isActionInProgress = true;
    forceUpdate();

  }

 
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {

    e.preventDefault();
    vm.isActionInProgress = false;
  //  console.log('UpdateColumnScreen onSubmit selectedColumn:', selectedColumn);
    //console.log('UpdateColumnScreen onSubmit configId:', data?.configId);
    //console.log('UpdateColumnScreen onSubmit  selectedColumn configId:', selectedColumn?.configId);
 
    var isValid = validate('all');
    if (!isValid) {
      console.log('UpdateColumnScreen onSubmit isValid:', isValid, vm.errors);
      forceUpdate();
      return false;
    }

    if(updateColumnAdvancedTabRef?.current && selectedColumn.isRelationship){
      isValid = updateColumnAdvancedTabRef?.current?.validateAll();
      console.log('UpdateColumnScreen onSubmit isValid2 updateColumnAdvancedTabRef validateAll start');
      if (!isValid) {
        console.log('UpdateColumnScreen onSubmit isValid3 updateColumnAdvancedTabRef isValid:', isValid, vm.errors);
        forceUpdate();
        return false;
      }
    }
    
    var sm = selectedColumn; 


/*     vm.errors.set('all', 'Dont save yet..');
    forceUpdate();
    return false; */

  

    //place it at the end for now
    /* sm.columnOrder = (columns.length + 1) * 10;
    sm.workspaceId = view?.workspaceId; */

    console.log('UpdateColumnScreen sm send:', sm);
    //return;

    var service = new App.ListColumnService(sm.workspaceId);

    service.updateColumn(sm,
      //onSuccess
      (result: any) => {
        console.log('UpdateColumnScreen onSuccess:', result);
 
        App.AppBase.showSaveSuccess();
   
        closeScreen(); 
        
        props.refreshData();


      },
      //onError
      onServiceError
      );
 
    vm.isActionInProgress = true;
    forceUpdate();
  
  }

  function onDeleteColumnClick(e: React.FormEvent<HTMLButtonElement>) {
   // console.log('UpdateColumnScreen onDeleteColumnClick');

    vm.isColumnDeleteOpen = true;

    if(vm.errors)
       vm.errors.clear();
    forceUpdate();
  }
  function onDeleteColumnCancel(e: React.FormEvent<HTMLFormElement>) {

    vm.isColumnDeleteOpen = false;

    if(vm.errors)
      vm.errors.clear();
    forceUpdate();
    
  }
  function onDeleteColumnConfirm(e: React.FormEvent<HTMLFormElement>) {

    // e.preventDefault();
 
     console.log('UpdateColumnScreen onDelete selectedColumn:', selectedColumn);
     //console.log('UpdateColumnScreen onSubmit configId:', data?.configId);
     //console.log('UpdateColumnScreen onSubmit  selectedColumn configId:', selectedColumn?.configId);
 
     
     var sm = selectedColumn;
     if(vm.errors)
      vm.errors.clear();
 
 
     //place it at the end for now
     /* sm.columnOrder = (columns.length + 1) * 10;
     sm.workspaceId = view?.workspaceId; */
 
     console.log('UpdateColumnScreen sm send:', sm);
     //return;
 
     var service = new App.ListColumnService(sm.workspaceId);
 
     service.deleteColumn(sm,
       //onSuccess
       (result: any) => {
         console.log('UpdateColumnScreen onSuccess:', result);
 
          
   
         App.AppBase.showSaveSuccess();
 
         closeScreen();
  
 
         props.refreshData();
 
         
 
       },
       //onError
       (error: any) => {
 
         console.error('UpdateColumnScreen onError:', error);
 
         let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
 
         vm.isColumnDeleteInProgress = false;
         vm.errors.set('all', errorMessage);
         forceUpdate();
       });
 
  
     vm.isColumnDeleteInProgress = true;
     forceUpdate();
 
   
 
   }

  function closeScreen(){
 
 
    vm.isColumnDeleteInProgress = false;
    vm.isColumnDeleteOpen = false;
    vm.isActionInProgress=false;
    
    if(vm.errors)
      vm.errors.clear();
     
    if(props.onClose)
      props.onClose();
 
    forceUpdate();
  }

 


  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('UpdateColumnScreen onInputChange key:', key);
    // console.log('UpdateColumnScreen onInputChange selectedColumn:', selectedColumn);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('UpdateColumnScreen onValueChange key:', key);
    //console.log('UpdateColumnScreen onValueChange value:', value);

    //console.log('UpdateColumnScreen onValueChange selectedColumn:', selectedColumn);

    if (key == 'name') {
      value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }  

    //@ts-ignore
    selectedColumn[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange selectedColumn', selectedColumn);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = selectedColumn;

    if(vm.errors)
      vm.errors.clear();

    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
        vm.errors.set('name', `Internal Name is required!`);
      else if (item.name.length > 50)
        vm.errors.set('name', `Internal Name must be less than 50 characters!`);
    }
    if (vAll || key == 'displayName') {
      if (LIB.Common.isNullOrEmpty(item.displayName))
        vm.errors.set('displayName', `Name is required!`);
      else if (item.displayName.length > 50)
        vm.errors.set('displayName', `Name must be less than 50 characters!`);
    }

    if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type is required!`);
    }
    else if ((vAll || key == 'listColumnTypeId') && isNaN(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type must be a number!`);
    }

    var isCalculatedType =  LY.ListColumnManager.isCalculatedColumn(item,true)

   
    if (vAll && isCalculatedType && 
      (LIB.Common.isNullOrEmpty(item.clientCalculation) && 
      LIB.Common.isNullOrEmpty(item.backendCalculation))
      ) {
        var calcMsg = `Either Client Calculation or Backend Calculation is Required!`;
        vm.errors.set('clientCalculation', calcMsg);
        vm.errors.set('backendCalculation', calcMsg);
      }

   /* if(item.isRelationship){
      if ((vAll || key == 'fkListId') && LIB.Common.isNullOrEmpty(item.fkListId)) {
        vm.errors.set('all', `Relationship List Selection is required!`);
      }
   } */
 
    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }

  
 


  // when Type changes, let's change what's selected 
  var listColumnTypeId = selectedColumn.listColumnTypeId;

  //console.log('UpdateColumnScreen watch listColumnTypeId:', listColumnTypeId);
  //console.log('UpdateColumnScreen selectedColumn:', selectedColumn);
  //console.log('UpdateColumnScreen columnTypes:', columnTypes);
  //console.log('UpdateColumnScreen selectedColumnType:', selectedColumnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == selectedColumn?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }
  /* if(selectedColumn.listColumnTypeId!=listColumnTypeId){
    selectedColumn.listColumnTypeId=listColumnTypeId;
    forceUpdate();
  } */



  // console.log('UpdateColumnScreen defaultTypeValue:', defaultTypeValue?.listColumnTypeId);
  //console.log('UpdateColumnScreen errors:', errors);



  function getForm(column: Api.ListColumn) {
   
    var item = selectedColumn;
    var columnTypes = props.state.columnTypes;
    var columns = props.state.view?.columns?.filter(x=>!x.isSystemHidden);
    //var columns = props.state.view.columns;

    var isCalculatedType =  LY.ListColumnManager.isCalculatedColumn(item,true)

    //console.log('UpdateColumnScreen getForm name:', item.name);
    //console.log('UpdateColumnScreen getForm isRelationship:', item.isRelationship);


    var form = (


        <div className='UpdateColumnScreenFormScrollArea' >

          <LY.LY_Input
            name="displayName"
            value={item.displayName}
            type='text'
            label="Display Name"
            placeholder="Display Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('displayName')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          /> 

<div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
            <LY.LY_Switch
              name="isRequired"
              checked={item.isRequired}
              //value={item.isRequired}
              label="Required"
              labelPlacement='left'
              // withStar={true}
              error={vm.errors.get('isRequired')}

              // helpText="Force unique values in this column."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />
            <LY.LY_Switch
              name="isRelationship"
              checked={item.isRelationship}
              value={item.isRelationship}
              label="Relationship"
              labelPlacement='left'
              // withStar={true}
              error={vm.errors.get('isRelationship')}

              // helpText="Force unique values in this column."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />
          </div>
 
          <MT.Alert variant="light" color="cyan" title={`Column Type: ${defaultTypeValue?.name || ''}`}
            style={{ marginTop: 0, minHeight: 50, padding: 8 }} >
            {defaultTypeValue?.description}
          </MT.Alert>

          {isCalculatedType && 
            <UpdateColumnAdvancedTab_Calculations  state={props.state} />
          }
 
          <LY.LY_Input
            name="name"
            value={item.name}
            type='text'
            label="Internal Name"
            placeholder="Internal Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('name')}
            disabled={item.isNameLocked}

            helpText="This will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          

          <LY.LY_Select
            name="afterColumnOrder"
            value={item.afterColumnOrder}
            label="Move After Column"
            placeholder="Select Column"
           // selectOptionValue={App.ListColumnTypeEnum.Other}

            data={columns || []}
            //value={defaultTypeValue?.listColumnTypeId}
            labelProperty="displayName"
            valueProperty="columnOrder"
           // withStar={false}
            error={vm.errors.get('afterColumnOrder')}

          // helpText="This name will be used in APIs or Integrations"
          // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

 
        </div>


    
    );

    return form;

  }




/* 
  function onOpenChange(open: boolean) {
    var vm = props.state.vm;

    vm.isColumnSettingsScreenOpen = open;
    vm.isColumnDeleteInProgress = false;
    vm.isActionInProgress = false;

    if(vm.errors)
      vm.errors.clear();

    //setOpen(open);
    forceUpdate();
  }
 */
  function getTabs() {

    var item = selectedColumn;
    var columnTypes = props.state.columnTypes;
    
    const iconStyle = { width: rem(12), height: rem(12) };
    var globalError = vm.errors.get('all');

   // console.log('UpdateColumnScreen globalError:', globalError);
    //console.log('UpdateColumnScreen globalError vm.errors:', vm.errors);

    var isDeleteLocked = (item.isSystemHidden || item.isDeleteLocked || item.isNameLocked || item.name == 'id' || item.name=='name');


    return (
      <div className="UpdateColumnTabContainer">

<form
        onSubmit={onSubmit}
        className='UpdateColumnScreenForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <Tabs defaultValue="basics" className='UpdateColumnTabContainerTabs'>
          <Tabs.List>
          <Tabs.Tab value="basics" leftSection={<Icons.IconForms style={iconStyle} />}>
              Basics
            </Tabs.Tab>
            <Tabs.Tab value="advanced" leftSection={<Icons.IconMessage style={iconStyle} />}>
              Advanced 
            </Tabs.Tab>
            
          </Tabs.List>
 
          <Tabs.Panel value="basics" className='UpdateColumnTabPanel'>

          {getForm(item)}
 
          </Tabs.Panel>
 

          <Tabs.Panel value="advanced" className='UpdateColumnTabPanel'>
           

            <UpdateColumnAdvancedTab 
              ref={updateColumnAdvancedTabRef}
            state={props.state}
           
            onRelationshipSelectionsDone={(svm: LY.ListColumnRelationshipSettingsVm) => {
              console.log('UpdateColumnScreen onRelationshipSelectionsDone svm: ', svm);
                
            }}


            />

          </Tabs.Panel>

         

        </Tabs>

        <Group  justify='right'>
          <LY.LY_ButtonCancel  onClick={() => closeScreen()} >Cancel</LY.LY_ButtonCancel>
          <LY.LY_ButtonDelete  

             disabled={isDeleteLocked && !list.isTemplate}  
             title={isDeleteLocked ? 'This column cannot be deleted!' : ''}

            onClick={onDeleteColumnClick}
          >
            Delete
          </LY.LY_ButtonDelete>

          <LY.LY_Button  type='submit'
            loading={vm.isActionInProgress} 
            loaderProps={{ type: 'dots' }}
          

          >
            Save
          </LY.LY_Button>
        </Group>

      </form>

      </div>
    );

  }

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var state = props.state;
    var propsVm = state.vm;
    var column = selectedColumn
    var displayName = column?.displayName;

    // console.log('UpdateColumnScreen render');

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || column == undefined || !propsVm.isColumnSettingsScreenOpen)
      return null;
 
    //  console.log('UpdateColumnScreen render isColumnDeleteOpen', vm.isColumnDeleteOpen);

      

    return (
      <>
        <Modal
          id='UpdateColumnScreenModal'
          opened={propsVm.isColumnSettingsScreenOpen}
          onClose={() => closeScreen()}
          title={`Update Column: ${column.displayName}`}
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}
          className={'LY_Global_Modals UpdateColumnScreenModal'}

          size={'lg'}
          closeOnClickOutside={false}
        >

          {getTabs()}
         {/*  {getForm(column)} */}


         <LIB.ConfirmModal
            visible={vm.isColumnDeleteOpen}
            onCancelClick={onDeleteColumnCancel}
            onConfirmClick={onDeleteColumnConfirm}
            title={`Confirm Delete: ${displayName}`}
            // content={`Are you sure you want to delete item "${vm.selectedItem?.name}"?`}

            content={
                <div>
                    <div>Are you sure you want to delete column <b>{displayName}</b>?</div>

                    <MT.Alert variant="light" color="red"
                        title={`COLUMN DELETE WARNING: ${displayName}`}
                        style={{ marginTop: 10, minHeight: 50, padding: 8 }} >

                        THIS WILL DELETE ALL ASSOCIATED COLUMN DATA!!

                    </MT.Alert>




                </div>}

            confirmButtonLabel="Delete"
            errorMessage={vm.apiErrorMessage}
            disabled={vm.isColumnDeleteInProgress}
        />

        </Modal>

      </>
    );
  }

  return render();
};


import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { useRef } from 'react';

import {
  Button, Icon, Tooltip, CircularProgress
} from '@mui/material';
import axios from 'axios';

import Box from '@mui/material/Box';

import * as Icons from '@tabler/icons-react';

import * as Api from 'app-api';

import '../ListTableView.css';

interface ListTableViewTableNewItemProps {
  state: App.ListState;
  onChange?: Function;
  //todo onNewItemSave: (sm:Api.ListInsertDataSm, e?: any) => void;
  listTableManager: LY.ListTableManager;
  className?: string;

  presetRelColumnName?: string;
  presetRelColumnValue?: string;

}

export const ListTableViewTableNewItem: React.FC<ListTableViewTableNewItemProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const newItemInput = useRef<HTMLInputElement>(null);
  //const newItem = useRef<LY.LY_TextInputHandle>(null);

  console.log('ListTableViewTableNewItem state',props.state);

  function onBtnSaveClick() {
    console.log('ListTableViewTableNewItem onNewItemSave');
    var newItem = props.state.newItem;

    newItem.loading = true;
    forceUpdate();

    //newItem.current?.sendUpdates();

    console.log('ListTableViewTableNewItem onNewItemSave newItem', newItem);
    // console.log('ListTableViewTableNewItem onNewItemSave newItem.current', newItem.current);
    //console.log('ListTableViewTableNewItem onNewItemSave newItemInput', newItemInput);

    onNewItemSave(newItem);


  }


  function onNewItemSave(model: LY.DataItemModel, e?: any) {

    console.log('onNewItemSave model:', model);
    console.log('onNewItemSave e:', e);
    var vm = props.state.vm;
    var newItem = props.state.newItem;


    if (model == undefined || LIB.Common.isNullOrEmpty(model?.value)){
        newItem.editing = false;
        forceUpdate();
        return;
    }
      
    if(model)
      model.listId = props.state.listId;


    var item = App.RecordManager.getInsertItem(model);

    var sm = new Api.ListInsertDataSm();
   
    if(props.presetRelColumnName && props.presetRelColumnValue && props.presetRelColumnName!='name' && item.data){
      item.data['name'] = ' ';
    }
    sm.items = [ item ];
    console.log('onNewItemSave sm:', sm);

    props.listTableManager.onNewItemSave(sm, e);

  }


  function getNewItemMode(){


    if(props.presetRelColumnValue && props.presetRelColumnName)
     return getNewItemLink();

    return getNewItemTextInput();

  }

  function getNewItemLink(){

    var state = props.state;
    var vm = state.vm;
    var newItem = state.newItem;

    var m = new LY.DataItemModel();
    m.name = props.presetRelColumnName!;
    m.value = props.presetRelColumnValue;
    m.editing = true;
    m.listId = state.listId;

    console.log('getNewItemLink m:', m);


    var result =  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <div
      className='LY_NameColumnInputContainer'>
      <a  
        className='LY_WebsiteInputLink'
        
        onClick={(e)=>{
          onNewItemSave(m,e)
        }}
        >+ Add Item</a>
  
    </div>
  
    {newItem.loading && <CircularProgress style={{ boxShadow: 'none' }} size="1rem" />}
 

  </div>
    

  return result
  }

  
  function getNewItemTextInput(){

    var state = props.state;
    var vm = state.vm;
    var newItem = state.newItem;

    var result =  <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap:5 }}>
       
       <LY.LY_TextInput
    key={'newItem'}

    inputRef={newItemInput}
    viewId={vm.viewId}
    model={newItem}
    name='name'
    placeholder='+ Add Item'
    inputClassName='addNewItemInput'
    onEnter={onNewItemSave}
    //onChangesDone={onNewItemSave}
    clearOnBlur={true}
    required={true}
    onEditingChange={(m: LY.DataItemModel) => {
      console.log('ListTableViewTableNewItem onEditingChange m:', m);
      newItem.editing = m.editing;
      console.log('ListTableViewTableNewItem onEditingChange editing:', newItem.editing);

      forceUpdate();
    }}
    onInputBlur={(e) => {
      console.log('ListTableViewTableNewItem onInputBlue');
      if(!newItem.value){
        newItem.editing =false;
        forceUpdate();
      }
   
    }}

  />

    {newItem.loading && <CircularProgress style={{ boxShadow: 'none' }} size="1rem" />}

    {newItem.editing==true &&

      <MT.Anchor key={'btnSave'}
        //className='addNewItemSaveBtn'
        onClick={onBtnSaveClick}
      >
        Save
      </MT.Anchor>
    }

  </div>
    

  return result
  }


  function render() {
    // console.log('ListTableViewTableNewItem render');
    var state = props.state;
    var vm = state.vm;
    var newItem = state.newItem;

    //console.log('ListTableViewTableNewItem render state', state);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded)
      return null;
  
    return <div className={`addNewItemContainer ${props.className || ''}`}>

      {getNewItemMode()}
  

      {/*  {(state.recordsCount>0 && 
      <div>Count: {state.recordsCount || ''} </div>
      )} */}
    </div>

  }

  return render();
};


import "../../LY_DoubleColorInput.css"

import * as LY from '_LY_Components'; // Assuming you have LY Buttons here

import { ColorInput, ColorPicker, Popover, Tooltip } from '@mantine/core';
import React, { useState } from 'react';

interface LY_DoubleColorInputPopoverProps {
    tooltip?: string;
    initialColor?: string;
    onColorChange?: (color: string) => void;
    swatches?: string[];
    colorType?: 'text' | 'background';
}

const defaultSwatches = [
    "#000000",
    "#FFFFFF",
    "#a9adb9",
    "#9093a4",
    "#808496",
    "#767c91",
    "#656a7e",
    "#585e72",
    "#4a5167",
    "#ffefcc",
    "#ffdd9b",
    "#ffca64",
    "#ffba38",
    "#ffb01b",
    "#ffab09",
    "#e39500",
    "#ca8500",
    "#af7100",
    "#a2e67e",
    "#87de57",
    "#75d940",
    "#6bd731",
    "#59be23",
    "#4da91b",
    "#3d920c",
    "#9ae2ff",
    "#64d2ff",
    "#3cc5fe",
    "#23bcfe",
    "#09b8ff",
    "#00a1e4",
    "#0090cd",
    "#007cb5",
    "#fba0a1",
    "#f76d6d",
    "#f34141",
    "#f22625",
    "#f21616",
    "#d8070b",
    "#c10008"
];

const LY_DoubleColorInputPopover: React.FC<LY_DoubleColorInputPopoverProps> = ({
    tooltip,
    initialColor,
    onColorChange,
    swatches = defaultSwatches,
}) => {
    const [opened, setOpened] = useState(false);
    const [selectedColor, setSelectedColor] = useState(initialColor);

    const handleColorChange = (color: string) => {
        setSelectedColor(color);
        onColorChange && onColorChange(color);
    };

    const border = selectedColor?.startsWith("w") ||
        selectedColor?.startsWith("#f") ||
        selectedColor?.startsWith("rgb(255") ? "1px solid #e2e2e2" : "";

    return (
        <Popover
            withArrow
            position="bottom"
            opened={opened}
            onClose={() => setOpened(false)}
        >
            <Popover.Target>
                <Tooltip label={tooltip}>
                    <div
                        className="LY_StaticSelect_Options_item_color_picker"
                        onClick={() => setOpened(true)}
                        style={{
                            backgroundColor: selectedColor || "#0C74E1",
                            border: border,
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }}
                    />
                </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2px' }}>
                    <ColorInput
                        classNames={{
                            saturation: 'LY_StaticSelect_Options_item_picker_saturation',
                            input: 'LY_StaticSelect_Options_item_picker_input',
                            root: 'LY_StaticSelect_Options_item_picker_input_wrapper',
                        }}
                        readOnly
                        value={selectedColor}
                        onChange={handleColorChange}
                    />
                    <ColorPicker
                        classNames={{
                            saturation: 'LY_StaticSelect_Options_item_picker_saturation',
                        }}
                        format="hex"
                        value={selectedColor}
                        onChange={handleColorChange}
                        swatchesPerRow={10}
                        swatches={swatches}
                    />
                    <div className='LY_StaticSelect_ButtonGroup'>
                        <LY.LY_ButtonCancel
                            onClick={() => setOpened(false)}
                        >
                            Cancel
                        </LY.LY_ButtonCancel>
                        <LY.LY_Button
                            onClick={() => setOpened(false)}
                        >
                            Ok
                        </LY.LY_Button>
                    </div>
                </div>
            </Popover.Dropdown>
        </Popover>
    );
};

export default LY_DoubleColorInputPopover;

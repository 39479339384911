import "./LY_StaticDropDown_Edit_Options.css";

import * as Api from 'app-api';
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Button, Input, Popover, Switch } from '@mantine/core';
import { DndContext, DragEndEvent, } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

import React from 'react';

interface SortableItemProps {
    id: string;
    children: React.ReactNode;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className="sortable-item"
        >
            <div
                {...listeners}
                style={{ cursor: 'grab', display: 'inline-block' }}
                className="sortable-item-handle"
            >
                <Icons.IconGripVertical />
            </div>
            {children}
        </div>
    );
};

interface LY_StaticDropDown_Edit_Options_Props extends LY.LY_StaticDropDownProps {
    onInputChange: (key: string, value: any, item: Api.SystemLookupType, index: number) => void;
}

export const LY_StaticDropDown_Edit_Options: React.FC<LY_StaticDropDown_Edit_Options_Props> = (props) => {
    const context = LY.useStaticDropDownContext();
    const state = context.state;

    const [deletedItem, setDeletedItem] = React.useState<Api.SystemLookupType | null>(null);
    const [search, setSearch] = React.useState('');
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    function handleDeleteOption() {
        if (!deletedItem?.id) return;
        context.deleteById(deletedItem.id);
        setDeletedItem(null);
    }



    function addOptionAndScroll() {
        const newItem = new Api.SystemLookupType();
        newItem.name = '';
        newItem.text_color = undefined;
        newItem.id = LIB.StringHelper.generateUUID();
        newItem.color = undefined;
        newItem.sort_order = state.items.length;
        context.addItem(newItem);
        scrollDown();
    }

    function scrollDown() {
        setTimeout(() => {
            if (wrapperRef.current) {
                wrapperRef.current.scrollTo({
                    top: wrapperRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, 100);
    }

    function searchInputRenderer() {
        if (state.items.length < 5)
            return null;

        return <Input
            classNames={{
                input: "LY_StaticSelect_Options_input",
                wrapper: "LY_StaticSelect_Options_input_wrapper"
            }}
            value={search}
            placeholder="Search..."
            onChange={e => setSearch(e.target.value)}
            leftSection={<Icons.IconSearch width={22} height={22} />}
        />;
    }

    function getRenderOptionItem(index: number, item: Api.SystemLookupType) {
        const nameInputName = 'edit_option_name' + index;
        const valueInputName = 'edit_option_value' + index;

        const type = props.valueProperty === 'value_id' ? 'number' : 'text';

        return (
            <SortableItem key={item.sort_order!} id={item.id!}>
                <LY.LY_DoubleColorInput
                    onInputChange={(value) => {
                        props.onInputChange('name', value, item, index);
                    }}
                    item={{
                        name: nameInputName,
                        text_color: item.text_color || "",
                        color: item.color || ""
                    }}
                    onColorChange={(type: string, value) => {
                        //  @ts-ignore
                        item[type] = value
                    }}
                    value={item.name}
                    placeholder="Name"
                    error={state.errors.get(nameInputName)}
                />
                {state.isShowingAdvancedSettings && props.valueProperty !== 'id' && (
                    <Input
                        name={valueInputName}
                        type={type.toLowerCase()}
                        value={item[props.valueProperty as keyof Api.SystemLookupType] as string || ""}
                        onChange={e => {
                            props.onInputChange(props.valueProperty, e.target.value, item, index);
                        }}
                        classNames={{
                            wrapper: 'LY_StaticSelect_Options_item_advancedInput_wrapper',
                            input: 'LY_StaticSelect_Options_item_advancedInput'
                        }}
                        placeholder={`${type || ""} Value`}
                        error={state.errors.get(valueInputName)}
                    />
                )}

                <MT.CloseButton
                    className="LY_StaticSelect_Options_item_delete"
                    icon={
                        <Icons.IconTrash
                            stroke={'1.5'}
                            style={{ minWidth: '16px', width: '16px' }}
                            aria-label="Delete Item"
                        />
                    }
                    onClick={() => setDeletedItem(item)}
                />
            </SortableItem>
        );
    }

    function renderOptions() {
        return (
            <Popover
                opened={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                width="auto"
                position="bottom"
                withArrow
            >
                <div className="LY_StaticSelect_Options_wrapper" ref={wrapperRef}>
                    <LIB.Loading show={state.vm.isActionInProgress} isModal disablePortal />
                    <SortableContext
                        items={state.items.map(item => item.id!)}
                        strategy={verticalListSortingStrategy}
                    >
                        {state.items.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
                            .map((item, index) => getRenderOptionItem(index, item))
                        }
                    </SortableContext>
                </div>
            </Popover>
        );
    }

    function advancedFilterRenderer() {
        return (
            <div className="LY_StaticSelect_Options_add_option">
                <Button
                    size="xs"
                    onClick={addOptionAndScroll}
                    classNames={{
                        root: 'LY_StaticSelect_Options_add_option_button',
                        inner: 'LY_StaticSelect_Options_add_option_button_inner',
                    }}
                    styles={{
                        section: { marginRight: 5 }
                    }}
                    variant='outline'
                    leftSection={<Icons.IconPlus stroke={1} size={20} />}
                >
                    Add
                </Button>

                {!state.isShowAdvancedSettingsDisabled &&
                    <Switch
                        label="Advanced Settings"
                        labelPosition='left'
                        onClick={(e) => {
                            state.isShowingAdvancedSettings = e.currentTarget.checked;
                            context.forceUpdate();
                        }}
                        checked={state.isShowingAdvancedSettings}
                        disabled={state.isShowAdvancedSettingsDisabled}
                        classNames={{
                            trackLabel: 'LY_StaticSelect_Options_switch_track_label',
                            body: 'LY_StaticSelect_Options_switch_body'
                        }}
                    />
                }
            </div>
        );
    }

    function deleteConfirmationModalRenderer() {
        return (
            <LIB.ConfirmModal
                visible={Boolean(deletedItem?.id)}
                onCancelClick={() => setDeletedItem(null)}
                onConfirmClick={handleDeleteOption}
                title={<div>Confirm Delete: <b>{deletedItem?.name}</b></div>}
                content={
                    <div>Are you sure you want to delete the <b>{deletedItem?.name}</b> Item?
                        <MT.Alert
                            variant="light"
                            color="red"
                            icon={<Icons.IconAlertTriangle />}
                            style={{ marginTop: 10, padding: 8 }}
                        >
                            There is NO Rollback for this action!
                        </MT.Alert>
                    </div>
                }
                confirmButtonLabel="Delete"
            />
        );
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const fromIndex = state.items.findIndex(item => item.id === active.id);
            const toIndex = state.items.findIndex(item => item.id === over?.id);
            context.moveItems(fromIndex, toIndex);
        }
    }

    return (
        <DndContext onDragEnd={handleDragEnd}>
            {searchInputRenderer()}
            {renderOptions()}
            {advancedFilterRenderer()}
            {deleteConfirmationModalRenderer()}
        </DndContext>
    );
};

export default LY_StaticDropDown_Edit_Options;

import * as React from 'react';
import * as LIB from '_LIB';
import * as App from 'AppReferences';
import * as Api from 'app-api';


import { Padding } from '@mui/icons-material';
import BillingLimitReachedModal from 'Pages/Account/Billing/BillingLimitReachedModal';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import './MainSplitPage.css';
import WorkspaceMenu from 'Components/WorkspaceMenu/WorkspaceMenu';
import ResizeHandle from './ResizeHandle';
import { CloseButton } from '@mantine/core';
import { DetailRightPanel } from 'Pages/Detail/DetailRightPanel/DetailRightPanel';
import WorkspaceLinkedWorkspaces from 'Components/WorkspaceLinkedWorkspaces/WorkspaceLinkedWorkspaces';


interface MainSplitPageProps {
    pageTitle?: any;
    state?: any;//LIB.BaseVmModel;
    loading?: boolean;
    inProgress?: boolean;
    showContentWhenInProgress?: boolean;
    hideContentWhenError?: boolean;
    hasDataLoaded?: boolean;
    showModalForLoading?: boolean;
    children?: React.ReactNode
    showError?: boolean;
    errorMessage?: string;
    alignError?: string;
    snackMessage?: string;
    showLeftMenu?: boolean;
    isChildPage?: boolean;
    isFullWidthPage?: boolean;
    isFullHeightPage?: boolean;
    isNarrowPage?: boolean;

    showRightPanel?: boolean;
    hasNoPageDesign?: boolean;
    mainPageContainerClassName?: string;
    mainPanelsClassName?: string;
    mainPanelsContentClassName?: string;
    mainContainerClassName?: string;

}



export const MainSplitPage: React.FC<MainSplitPageProps> = (props) => {

    //function title:string;



    function getLeftMenu() {

        var isMobile = App.AppBase.isMobile;
        if (!props.showLeftMenu || isMobile)
            return null;

        return <WorkspaceMenu />;
    }

    function getLinkedWorkspaces() {

        if (!props.showLeftMenu)// || state.isMobile)
            return null;


        return <div>



        </div>


    }




    function getBillingLimit() {

        var user = App.AppBase.currentUser;

        ///   console.log("getBillingLimit company",user?.company);
        //  console.log("getBillingLimit hasActiveBillingPlan",user?.company?.hasActiveBillingPlan);

        // return <BillingLimitReachedModal />
        //return null;

        if (!App.AppBase.isUserAuthenticated ||
            user?.company?.hasActiveBillingPlan ||
            user?.company?.hasActiveBillingPlan === undefined) {
            return null;
        }

        var currentPage = App.RouteManager.getCurrentUrlPage();

        //   console.log("getBillingLimit currentPage",currentPage);


        if (currentPage?.toLowerCase().includes('/account/'))
            return null;

        return null;
        /*         return <BillingLimitReachedModal /> */
    }




    function getErrorMessage(globalState: App.GlobalState): any {
        console.log("MainSplitPage.getErrorMessage");

        var error = props.errorMessage;

        if (LIB.Common.isNullOrEmpty(error))
            error = props.state?.apiErrorMessage;

        if (LIB.Common.isNullOrEmpty(error)) // if(error===undefined)
            error = globalState?.globalUIState?.apiErrorMessage;

        /* console.log("MainSplitPage.getErrorMessage props.errorMessage",props.errorMessage); 
        console.log("MainSplitPage.getErrorMessage props.state.apiErrorMessage",props.state.apiErrorMessage); 
        console.log("MainSplitPage.getErrorMessage globalState?.globalUIState?.apiErrorMessage",globalState?.globalUIState?.apiErrorMessage); 
 */
        //console.log("MainSplitPage props",props); 

        return error;
    }

    function render() {

        // var isMobile = getst
        // console.log("MainSplitPage.render isMobile:", state.isMobile);

        var globalState = App.AppBase.getState().globalData;
        // var error = props.state?.apiErrorMessage;

        var error = getErrorMessage(globalState);

        var children: any = props.children;
        var state = props.state;
        var workspaceVm = App.AppBase.getState().globalData.workspaceVm;
        var selectedWorkspace = workspaceVm?.selectedWorkspace;

        var hasDataLoaded = props.hasDataLoaded;
        var hideContentWhenError = props.hideContentWhenError;

        if (hasDataLoaded === undefined)
            hasDataLoaded = true;

        if (!state || !hasDataLoaded) {
            //console.log("MainSplitPage.render Data Not Loaded yet");
            children = undefined;
        }

        if ((!LIB.Common.isNullOrEmpty(error) && hideContentWhenError)) {
            //  console.log("MainSplitPage.render error, and hideContentWhenError : ", error);
            children = undefined;
        }

        var loginWarning: any = "";


        if (!props.loading && App.RouteManager.isLoginWarningRoute()) {
            loginWarning = App.AppBase.getLoginToForFullAccessWarning();

        }

             // hasNoPageDesign={true}
   

     var hasNoPageDesign = props.hasNoPageDesign;
     var mainPageContainerClassName = props.mainPageContainerClassName;
     var mainPanelsContentClassName = props.mainPanelsContentClassName;
     var mainContainerClassName = props.mainContainerClassName
     var mainPanelsClassName = props.mainPanelsClassName;
    
     var leftMenu = getLeftMenu();
        
     if(props.isChildPage || props.isFullWidthPage || (props.showLeftMenu && !leftMenu)){
        hasNoPageDesign = true;
        mainPageContainerClassName='LY_MainContainerFullPageWidth'
        mainPanelsContentClassName='LY_MainContainerContentFullPageWidth'
     }

     if(props.isNarrowPage){

        mainPageContainerClassName='LY_MainSplitNarrowPage_MainPageContainer'

        mainPanelsContentClassName=`LY_MainSplitNarrowPage_MainPanelsContent ${props.mainPanelsContentClassName || ''}`

        mainContainerClassName='LY_MainContainerAutoHeight'
        mainPanelsClassName="LY_MainSplitNarrowPage_MainPanels"

     }




     if(props.isFullHeightPage){
        mainPageContainerClassName+=' LY_MainSplitPageFullPageHeight';
     }

        if (hasNoPageDesign || !leftMenu) {

              console.log("MainSplitPage.render hasNoPageDesign:", props.hasNoPageDesign);
               
            return <div
                className={`MainPageContentCenter ${mainPageContainerClassName || ''}`}  >

                <App.MainContainer

                    {...props}
                    className={mainPanelsContentClassName }
                />
            </div>

        }
        /*  else if(!props.showLeftMenu){
 
             return <div className={`MainSplitPageContainer ${props.mainPageContainerClassName || ''}`}>
             {children}
             </div>
 
         } */

        return <PanelGroup
            direction="horizontal"
            className={`MainSplitPageContainer ${props.mainPageContainerClassName || ''}`}

        >


            {/*   ---------------- LEFT MENU ------------------------------------*/}
            {props.showLeftMenu &&
    

                <Panel id='mainLeftPanel'
                    className={`MainSplitPage-MainPanels ${mainPanelsClassName || ''}`}
                    defaultSize={15} minSize={15} order={1}>
 
                    <PanelGroup direction="vertical" className='MainSplitLeftPanels'>

 
              

                        <Panel className='MainSplitPagePanel' defaultSize={50}>
                       
                            <div className='MainSplitPagePanelContent-LeftMenu'  >
                           
                                {leftMenu}
                            </div>

                        </Panel>


                        {props.showLeftMenu && selectedWorkspace?.linkedWorkspaces && selectedWorkspace?.linkedWorkspaces?.length > 0 && (
                            <>
                                <ResizeHandle />



                                <Panel className='MainSplitPagePanel'>

                                    <div className='MainSplitPagePanelContent-LeftMenu' >
                                        <WorkspaceLinkedWorkspaces />

                                    </div>

                                </Panel>
                            </>
                        )}
                    </PanelGroup>
                </Panel>
            }
            {/*   ---------------- LEFT MENU END ------------------------------------*/}


            {/* <PanelResizeHandle />
 */}
            <ResizeHandle />

            {/*   ---------------- CENTER ------------------------------------*/}

            <Panel
                className={`MainSplitPage-MainPanels ${props.mainPanelsClassName || ''}`}

                order={2} >

                <div 
                className={`MainSplitPagePanelContent ${props.mainPanelsContentClassName || ''}`}

                  >

                    {loginWarning}
                    {getBillingLimit()}
                    <App.MainContainer

                        {...props}
                        className={mainContainerClassName}
                    />
                    {loginWarning}

                </div>

            </Panel>
            {/*   ---------------- CENTER END ------------------------------------*/}

            {/*   <PanelResizeHandle /> */}

            {/*   ---------------- RIGHT  ------------------------------------*/}



        </PanelGroup>




    }

    return render();
}
